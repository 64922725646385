// hooks/useEncounter.js

import { useStore } from "../Store";
import { client } from "../api/axiosClient";
import { useAuth } from "./useAuth";
import {
    SaveObservation,
    resetObservationForm,
  } from "../components/Observation";

export const useEncounter = () => {
  const {
    currentSessionInfo,
    setCurrentSessionInfo,
    selectedPatientInfo,
    setShowEncouterObjects,
    ordonnanceData,
    setOrdonnanceData,
    biologieData,
    setBiologieData,
    radiologieData,
    setRadiologieData,
    courrierData,
    dispositifMedicalData,
    setDispositifMedicalData,
    activeEncounter,
    setActiveEncounter,
    observationData,
    setObservationData,
    setHistoriqueData,
    setActive,
    setActiveTab,
    setMiddleActiveTab,
    setMiddleActive,
    setShouldSaveCourrier,
    ordonnanceLibreData,
    setOrdonnanceLibreData,
    setEditorState,
    setNewEditorState,
    setHpoList

  } = useStore();

  const { currentUser, setCurrentUser, error, logout } = useAuth();

  const handleHistoriqueControlChange = (value) => {
    setMiddleActive(value);
    setMiddleActiveTab(value);
  };
  const CreateEncounter = async () => {
    if (activeEncounter) {
      console.log("Consultation active déjà en cours");
      // console.log(activeEncounter);
      // console.log(currentSessionInfo);
      return;
    }

    try {
      // Si pas de consultation active en cours, créer une nouvelle consultation active
      setShowEncouterObjects(true);
      setMiddleActive("Observation Médicale");
      setMiddleActiveTab("Observation Médicale");
      setActive("medicaments");
      setActiveTab("medicaments");
      setEditorState([])
      setNewEditorState([])
      setOrdonnanceLibreData([])
      setObservationData([])
      setHpoList([])
      const encounterData = {
        practitioner: currentUser.id,
        subject: selectedPatientInfo.id,
        status: "active",
      };

      const response = await client.post("api/encounters/", encounterData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        const currentSessionData = {
          practitioner: currentUser.id,
          subject: selectedPatientInfo.id,
          encounter: response.data.id,
        };
        setCurrentSessionInfo(currentSessionData);

        try {
          const response_2 = await client.get(
            `api/current-session/?practitioner=${currentUser.id}`
          );
          if (response_2.status === 200) {
            const response_3 = await client.put(
              `api/current-session/${response_2.data.results[0].id}/`,
              currentSessionData,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            if (response_3.status === 200 || response_2.status === 204) {
              // setShowEncouterObjects(true);
              setActiveEncounter(true);
            } else {
              console.error("Failed to update currentSession:", response_2);
            }
          } else {
            const response_3 = await client.post(
              "api/current-session/",
              currentSessionData,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
          }
        } catch (error) {
          console.error("Failed to update currentSession:", error);
        }
      } else {
        console.error("Failed to create encounter:", response);
      }
    } catch (error) {
      console.error("Failed to create encounter:", error);
    }
  };

  const SaveEncounter = async () => {
    const EncounterData = {
      observationMedicale: observationData || {},
      ordonnanceMedicaments: ordonnanceData || [],
      ordonnanceBiologie: biologieData || [],
      ordonnanceRadiologie: radiologieData || [],
      ordonnanceDispositifMedical: dispositifMedicalData || [],
      courrierMedical: courrierData || [],
      ordonnanceLibre: ordonnanceLibreData || [],
    };
    console.log("EncounterData au début de SaveEncounter:", EncounterData);
    // console.log("currentSessionInfo:", currentSessionInfo);
    async function fetchEncounters() {
      try {
        const response = await client.get(
          `api/encounters/?limit=20&practitioner=${currentSessionInfo.practitioner}&subject=${currentSessionInfo.subject}&status=archived`
        );
        setHistoriqueData(response.data.results);
        
      } catch (error) {
        console.error("Failed to fetch encounters:", error);
      }
    }

    const areAllContentValuesNull = (data) => {
      if (!data) return true;
      if (Array.isArray(data)) {
        return data.every(
          (item) => !item || item.content === null || item.content === ""
        );
      }
      if (typeof data === "object") {
        return Object.values(data).every((value) =>
          areAllContentValuesNull(value)
        );
      }
      return data === null || data === "";
    };
    
    // Vérifier si toutes les propriétés de EncounterData sont vides ou null
    if (
      areAllContentValuesNull(
        EncounterData.observationMedicale.observationContent
      ) &&
      areAllContentValuesNull(EncounterData.ordonnanceMedicaments) &&
      areAllContentValuesNull(EncounterData.ordonnanceBiologie) &&
      areAllContentValuesNull(EncounterData.ordonnanceRadiologie) &&
      areAllContentValuesNull(EncounterData.ordonnanceDispositifMedical) &&
      areAllContentValuesNull(EncounterData.ordonnanceLibre) &&
      areAllContentValuesNull(EncounterData.courrierMedical.content) 
    ) {
      console.log("nothing to save");
      setShowEncouterObjects(false);
      setActiveEncounter(false);
      handleHistoriqueControlChange("Historique des Consultations");

      try {
        const response = await client.delete(
          `api/encounters/${currentSessionInfo.encounter}/`
        );
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de la consultation active:",
          error
        );
      }
    } else {
      try {
        console.log(currentSessionInfo);
        const response = await client.patch(
          `api/encounters/${currentSessionInfo.encounter}/`,
          {
            encounterData: EncounterData,
            status: "archived",
          }
        );
        if (response.status === 200) {
          console.log("Encounter saved");
          setMiddleActive("Historique des Consultations");
          setMiddleActiveTab("Historique des Consultations");
          setShowEncouterObjects(false);
          setOrdonnanceData([]);
          setBiologieData([]);
          setRadiologieData([]);
          setDispositifMedicalData([]);
          setOrdonnanceLibreData([]);
          setEditorState([]);
          setObservationData([]);
          setActiveEncounter(false);
          setShouldSaveCourrier(true);
          setHpoList([]);

          // setRadiologieData([]);
          // setPresLibreData([]);
          // const response = await client.patch(
          //   `api/current-session/${currentSessionInfo.id}/`,
          //   {
          //     encounter: null,
          //   }
          // );

          const success = await SaveObservation(
            setObservationData,
            observationData
          );
          if (success) {
            resetObservationForm();
          }
          fetchEncounters();
        }
      } catch (error) {
        console.error(
          "Erreur lors de la sauvegarde de la consultation:",
          error
        );
      }
    }
  };

  return { CreateEncounter, SaveEncounter };
};
