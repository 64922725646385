import { useStore } from "../Store";
import { useState, useEffect, useRef } from "react";
import {
  Group,
  Stack,
  TextInput,
  Textarea,
  Switch,
  rem,
  Title,
  Image,
  Text,
  JsonInput,
  Button,
  CloseButton
} from "@mantine/core";
import {
  IconX,
  IconCheck,
  IconStethoscope,
  IconArrowLeft,

} from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { RecordConsultation } from "./AudioRecorder";

import { notifications } from "@mantine/notifications";
import { GetHypothesesDiag } from "./HypothesesDiag";
import { client } from "../api/axiosClient";
import brain from "../brain_ai_3.png";

export const SaveObservation = async (setObservationData, observationData) => {
  try {
    if (observationData.synthese) {
      setObservationData(observationData);
      const response = await client.post("api/observations/", observationData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        setObservationData([]);
        console.log("Observation enregistrée");
        return true; // Indiquer que la sauvegarde a réussi
      } else {
        console.error("Failed to save new observation:", response);
        return false; // Indiquer que la sauvegarde a échoué
      }
    } else {
      setObservationData([]);
    }
  } catch (error) {
    console.log(error);
    return false; // Indiquer que la sauvegarde a échoué
  }
};

export let resetObservationForm = () => {};

export const ObservationForm = () => {
  const [checked, setChecked] = useState(true);
  const {
    setObservationData,
    currentSessionInfo,
    observationValues,
    currentNotification,
    setConsultationTranscription,
    hpoList,
    setHpoList
  } = useStore();

  const closeDialog = useStore((state) => state.close);


  const form = useForm({
    initialValues: {
      motif: "",
      temperature: "",
      pressionArterielle: "",
      frequenceCardiaque: "",
      spO2: "",
      poids: "",
      synthese: "",
      symptomes: "",
      signes: "",
      biologie: "",
      radiologie: "",
    },
    validate: {
      synthese: (value) =>
        value.length < 1 ? "Votre Observation est vide" : null,
    },
    onValuesChange: (values) => {
      updateObservationData(values);
    },
  });

  resetObservationForm = () => {
    form.reset();
  };

  useEffect(() => {
    if (observationValues) {
      const currentValues = form.values;

      const motif = currentValues.motif
        ? `${currentValues.motif}${
            observationValues?.Synthese?.Motif
              ? ", " + observationValues.Synthese.Motif
              : ""
          }`
        : observationValues?.Synthese?.Motif || "";
      const synthese = currentValues.synthese
        ? `${currentValues.synthese}${
            observationValues?.Synthese?.Resume
              ? "\n" + observationValues.Synthese.Resume
              : ""
          }`
        : observationValues?.Synthese?.Resume || "";

      const temperature =
        observationValues?.Constantes?.Temperature || currentValues.temperature;
      const pressionArterielle =
        observationValues?.Constantes?.PressionArterielle ||
        currentValues.pressionArterielle;
      const poids = observationValues?.Constantes?.Poids || currentValues.poids;
      const frequenceCardiaque =
        observationValues?.Constantes?.FrequenceCardiaque ||
        currentValues.frequenceCardiaque;
      const spO2 = observationValues?.Constantes?.SpO2 || currentValues.spO2;

      const symptomes = currentValues.symptomes
        ? `${currentValues.symptomes}${
            observationValues?.Clinique?.Symptomes?.length
              ? ", " +
                observationValues.Clinique.Symptomes.map(
                  (item) => item.txt
                ).join(", ")
              : ""
          }`
        : observationValues?.Clinique?.Symptomes?.map((item) => item.txt).join(
            ", "
          ) || "";
      const signes = currentValues.signes
        ? `${currentValues.signes}${
            observationValues?.Clinique?.Signes?.length
              ? ", " +
                observationValues.Clinique.Signes.map((item) => item.txt).join(
                  ", "
                )
              : ""
          }`
        : observationValues?.Clinique?.Signes?.map((item) => item.txt).join(
            ", "
          ) || "";
      const biologie = currentValues.biologie
        ? `${currentValues.biologie}${
            observationValues?.Clinique?.Biologie?.length
              ? "\n" +
                observationValues.Clinique.Biologie.map(
                  (item) => item.txt
                ).join("\n")
              : ""
          }`
        : observationValues?.Clinique?.Biologie?.map((item) => item.txt).join(
            "\n"
          ) || "";
      const radiologie = currentValues.radiologie
        ? `${currentValues.radiologie}${
            observationValues?.Clinique?.Radiologie?.length
              ? "\n" +
                observationValues.Clinique.Radiologie.map(
                  (item) => item.txt
                ).join("\n")
              : ""
          }`
        : observationValues?.Clinique?.Radiologie?.map((item) => item.txt).join(
            "\n"
          ) || "";

      notifications.update({
        id: currentNotification,
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src={brain}
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>Transcription Terminée</Text>
            <IconCheck
              style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
            />
          </Group>
        ),
        opacity: 0.7,
        autoClose: 1000,
        withCloseButton: true,
      });

      // Mettre à jour les valeurs du formulaire
      form.setValues({
        motif,
        synthese,
        temperature,
        pressionArterielle,
        frequenceCardiaque,
        spO2,
        poids,
        symptomes,
        signes,
        biologie,
        radiologie,
      });
      setConsultationTranscription("");
      // closeDialog();
    }
  }, [observationValues]);

  const handleSubmit = async (values) => {
    try {
      const ObservationData = {
        practitioner: currentSessionInfo?.practitioner,
        subject: currentSessionInfo?.subject,
        encounter: currentSessionInfo?.encounter,
        status: "active",
        category: "observation médicale",
        code: null,
        valueQuantity: null,
        unit: null,
        motif: values.motif || "",
        synthese: values.synthese || "",
        observationContent: values,
      };
      setObservationData(ObservationData);
      const response = await client.post("api/observations/", ObservationData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 201) {
        resetObservationForm();
        console.log("Observation enregistrée");
        return true; // Indiquer que la sauvegarde a réussi
      } else {
        console.error("Failed to save new observation:", response);
        return false; // Indiquer que la sauvegarde a échoué
      }
    } catch (error) {
      console.log(error);
      return false; // Indiquer que la sauvegarde a échoué
    }
  };

  const updateObservationData = (values) => {
    const ObservationData = {
      practitioner: currentSessionInfo?.practitioner,
      subject: currentSessionInfo?.subject,
      encounter: currentSessionInfo?.encounter,
      status: "active",
      category: "observation médicale",
      code: null,
      valueQuantity: null,
      unit: null,
      motif: values.motif || "",
      synthese: values.synthese || "",
      observationContent: values,
    };
    setObservationData(ObservationData);
  };

  // Fonction pour supprimer un terme HPO de la liste
  const DeleteHpo = (hpoId) => {
    if (!hpoList || !Array.isArray(hpoList)) return;
    
    // Filtrer la liste pour supprimer l'élément avec l'ID spécifié
    const updatedHpoList = hpoList.filter(hpo => hpo.hpo_id !== hpoId);
    
    // Mettre à jour la liste dans le store
    setHpoList(updatedHpoList);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Group justify="space-between">
          <Group>
            <IconStethoscope color="#94ADA7" />
            <Title order={5} c="#94ADA7">
              Observation
            </Title>

            <Switch
              defaultChecked
              onLabel="détaillée"
              offLabel="détaillée"
              onChange={(event) => setChecked(event.currentTarget.checked)}
              color="teal"
              thumbIcon={
                checked ? (
                  <IconCheck
                    style={{ width: rem(12), height: rem(12) }}
                    color="teal"
                    stroke={3}
                  />
                ) : (
                  <IconX
                    style={{ width: rem(12), height: rem(12) }}
                    color="red"
                    stroke={3}
                  />
                )
              }
            />

             <RecordConsultation />
             <Group gap="xs">
          <IconArrowLeft size={14} />
          <Text size="xs">Cliquez ici Enregistrer la Consultation</Text>
        </Group>
          </Group>
          <Group>
            <GetHypothesesDiag />
          </Group>
        </Group>

        <TextInput
          variant="filled"
          radius="12px"
          label={<Text size="xs">Motif</Text>}
          size="sm"
          placeholder="Motif de la consultation"
          {...form.getInputProps("motif")}
        />
        <Group grow>
          <TextInput
            variant="filled"
            radius="12px"
            label={<Text size="xs">Température</Text>}
            size="sm"
            placeholder="°C"
            classNames={{ input: "custom-placeholder" }}
            {...form.getInputProps("temperature")}
          />
          <TextInput
            variant="filled"
            radius="12px"
            label={<Text size="xs">Pression Artérielle</Text>}
            size="sm"
            placeholder="mmHg"
            classNames={{ input: "custom-placeholder" }}
            {...form.getInputProps("pressionArterielle")}
          />
          <TextInput
            variant="filled"
            radius="12px"
            label={<Text size="xs">Fréquence Cardiaque</Text>}
            size="sm"
            placeholder="Bpm"
            classNames={{ input: "custom-placeholder" }}
            {...form.getInputProps("frequenceCardiaque")}
          />
          <TextInput
            variant="filled"
            radius="12px"
            label={<Text size="xs">Saturation en Oxygène</Text>}
            size="sm"
            placeholder="SpO2 %"
            classNames={{ input: "custom-placeholder" }}
            {...form.getInputProps("spO2")}
          />
          <TextInput
            variant="filled"
            radius="12px"
            label={<Text size="xs">Poids</Text>}
            size="sm"
            placeholder="Kg"
            classNames={{ input: "custom-placeholder" }}
            {...form.getInputProps("poids")}
          />
        </Group>

        <Textarea
          withAsterisk
          variant="filled"
          radius="12px"
          label={<Text size="xs">Synthèse</Text>}
          size="sm"
          placeholder="Resumé de la consultation"
          {...form.getInputProps("synthese")}
          autosize
          resize="vertical"
          minRows={checked ? 6 : 16}
        />

        {checked && (
          <>
            <Group grow>
              <Textarea
                variant="filled"
                radius="12px"
                label={<Text size="xs">Symptômes</Text>}
                size="sm"
                placeholder="Liste des symptômes"
                {...form.getInputProps("symptomes")}
                resize="vertical"
                autosize
                minRows={5}
                maxRows={5}
              />

              <Textarea
                variant="filled"
                radius="12px"
                label={<Text size="xs">Signes</Text>}
                size="sm"
                placeholder="Liste de signes physiques"
                {...form.getInputProps("signes")}
                resize="vertical"
                autosize
                minRows={5}
                maxRows={5}
              />
            </Group>
            <Group grow>
              <Textarea
                variant="filled"
                radius="12px"
                label={<Text size="xs">Biologie</Text>}
                size="sm"
                placeholder="Resumé des resultats de la biologie"
                {...form.getInputProps("biologie")}
                resize="vertical"
                autosize
                minRows={5}
                maxRows={5}
              />
              <Textarea
                variant="filled"
                radius="12px"
                label={<Text size="xs">Radiologie</Text>}
                size="sm"
                placeholder="Résumé des resultats des exmaens de radiologie"
                {...form.getInputProps("radiologie")}
                resize="vertical"
                autosize
                minRows={5}
                maxRows={5}
              />
            </Group>
            {hpoList && hpoList.length > 0 && (
              <Group mb="md">
                {hpoList.map((hpo) => (
                  <Button
                    key={hpo.hpo_id}
                    className="antFamButton"
                    justify="space-between"
                    size="xs"
                    variant="outline"
                    radius="12px"
                    color="#94ADA7"
                    rightSection={
                    
                        <CloseButton
                          onClick={() => {
                            DeleteHpo(
                              hpo.hpo_id,
                            );
                          }}
                          icon={
                            <IconX size={18} stroke={1.5} color="red" />
                          }
                        />
                    
                    }
                   
                  >
                    <Text size="xs" c="black">{`HP : ${hpo.hpo_id} | ${hpo.hpo_name}`}</Text>
                  </Button>
                ))}
              </Group>
            )}
          </>
        )}
        {/* 
        <Button type="submit" mt="md" radius="12px" color="#94ADA7">
          Sauvegarder l'Observation
        </Button> */}
      </Stack>
    </form>
  );
};
