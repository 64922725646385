// LoginComponent.jsx

import  { useState } from 'react';
import { client } from '../api/axiosClient';
import { Button, Text, Stack, TextInput, PasswordInput, PinInput } from "@mantine/core";
import React from 'react';

export function LoginComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [otpCode, setOtpCode] = useState(null);
    const [uid, setUid] = useState(null);
    const [message, setMessage] = useState('');

    const loginStepOne = async () => {
        try {
            const response = await client.post('/api/login/', {
                username,
                password,
            });
            if (response.status ===200 && response.data.two_factor_required) {
                // 2FA requis
                setUid(response.data.uid);
                setMessage("Veuillez entrer votre code OTP..");
            } else {
                // Authentification réussie sans 2FA
                setMessage(response.data.detail);
                // Rediriger ou mettre à jour l’interface utilisateur
            }
        } catch (error) {
            console.error(error);
            setMessage(error.response ? error.response.data.detail :  "Échec de la connexion.");
        }
    };

    const loginStepTwo = async () => {
        try {
            console.log({"uid":uid,"otp_code":otpCode})
            const response = await client.post('/api/login/verify-otp/', {
                uid,
                otp_code: otpCode,
            });
            // Authentification réussie
            localStorage.setItem('token', response.data.token);
            setMessage("Connexion réussie.");
            // Rediriger ou mettre à jour l’interface utilisateur
        } catch (error) {
            console.error(error);
            setMessage(error.response ? error.response.data.detail :  "Échec de la connexion.");
        }
    };

    return (
        <Stack>
            {/* <h2>Connexion</h2> */}
            {!uid ? (
                // Première étape : Validation du mot de passe
                <Stack  justify="stretch"  w="13.5vw">
                    <TextInput

                        type="text"
                        radius="12px"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Numéro RPPS"
                        
                    />
                    <PasswordInput
                    
                        type="password"s
                         radius="12px"
                        value={password}
                        onChange={(e) => setPassword(e.target?.value)}
                        placeholder="Mot de passe"
                       
                        
                    />
                    <Button 
                     className="printButton"
                     variant="light"
                     size="md"
                     color="#94ADA7"
                     radius="12px"
                     mt="10"
                    
                     
                     onClick={loginStepOne}>Connexion</Button>
                </Stack>
            ) : (
                // Deuxième étape : Validation du code OTP
                <Stack justify="stretch">
                    <Text c="#94ADA7" fw={500} style={{fontSize:15, fontFamily:"Palatino"}} >Code OTP</Text>
                    {/* <PasswordInput
                        type="text"
                        value={otpCode}
                        onChange={(e) => setOtpCode(e.target.value)}
                        placeholder="Code OTP"
                    /> */}
                     <PinInput
                        aria-label="Code OTP"
                        value={otpCode}
                        length={6}
                        onComplete={(value) => setOtpCode(value)}
                        />
                    <Button 
                     className="printButton"
                     variant="light"
                     size="md"
                     color="#94ADA7"
                     radius="12px"
                     mt="10"
                    onClick={loginStepTwo}>Valider le code OTP</Button>
                </Stack>
            )}
            {message && <Text c="#94ADA7"  fw={500} style={{fontSize:17, fontFamily:"Palatino"}} >{message}</Text>}
        </Stack>
    );
}


