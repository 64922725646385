import { useStore } from "../Store";
import { useState, useEffect } from "react";
import {
  Button,
  Image,
  Card,
  Text,
  Stack,
  Group,
  Title,
  Grid,
  Loader,
  RingProgress,
  Tooltip,
  ScrollArea
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { ExamensComplementairesButton } from "./ExamensComplementaires";
import { PropositionsTherapeutiquesButton } from "./PropositionsTherapeutiques";

import { IconCheck, IconBrain, IconPills } from "@tabler/icons-react";
import {client} from "../api/axiosClient"
import brain from "../brain_ai_3.png";

export function GetHypothesesDiag(synthese) {
  // const observationData = useStore((state) => state.observationData);
  // const setHypothesesDiagnostiques = useStore(
  //   (state) => state.setHypothesesDiagnostiques
  // );
  // const setCurrentNotification = useStore(
  //   (state) => state.setCurrentNotification
  // );

  const {
    observationData,
    setHypothesesDiagnostiques,
    setCurrentNotification,
    setMiddleActive,
    setMiddleActiveTab,
    getMiddleData, 
    commandGetHypotheses,
    setCommandGetHypotheses,
  } = useStore();

  if (synthese){
    const syntheseData = synthese
  }

  const FetchHypotheses = async (synthese) => {
    try {
      const middleData = getMiddleData();
      const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src={brain}
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>Probabilités Diagnostiques</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,
      });
      setCurrentNotification(notificationId);
      const response = await client.get(
        `api/hypotheses/?synthese=${observationData.synthese}`
      );
      console.log(response);
      if (response.status === 200) {
        notifications.update({
          id: notificationId,
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src={brain}
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Recherche Terminée</Text>
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            </Group>
          ),
          opacity: 0.7,
          autoClose: 1000,
          withCloseButton: true,
        });
        setHypothesesDiagnostiques(response.data);
        middleData.push({
          value: "Hypotheses Diagnostiques",
          label: (
            <Group style={{ gap: '0.5rem' }} justify="center">
              <IconBrain size="16" />
              <Text size="xs">Diagnostic</Text>
            </Group>
          ),
        });
        setMiddleActive("Hypotheses Diagnostiques");
        setMiddleActiveTab("Hypotheses Diagnostiques");
      }
    } catch (error) {
      console.error("Error fetching hypotheses:", error);
    }
  };

  useEffect(() => {
    if (commandGetHypotheses){
      FetchHypotheses();
      setCommandGetHypotheses(false);
    }
  },[commandGetHypotheses])

  return (
    <Tooltip
      label={
        <Text size="xs">
          Rechercher des Hypothèses Diagnostiques à partir du contenu de
          l'Observation
        </Text>
      }
      color="#94ada7c7"
      withArrow
      multiline
      w={150}
      position="left"
      radius="12px"
      arrowOffset={25}
      arrowSize={8}
      transitionProps={{ transition: "pop", duration: 500 }}
      openDelay={1000}
    >
      <Button
        className="hypothesesButton"
        onClick={FetchHypotheses}
        variant="outline"
        size="xs"
        color="#94ADA7"
        radius="12px"
        style={{ boxShadow: "0 2px 5px #bebebe" }}
      >
        <IconBrain color="#94ADA7" size={19} />
      </Button>
    </Tooltip>
  );
}

export function ShowHypotheses() {
  const hypothesesDiagnostiques = useStore(
    (state) => state.hypothesesDiagnostiques
  );
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // Set a minimum size or calculate based on the container
    setChartSize({ width: 100, height: 100 });
  }, []);
  return (
    <Stack>
      {hypothesesDiagnostiques && hypothesesDiagnostiques.hypotheses && (
       
          <>
           <ScrollArea h={'82vh'} offsetScrollbars scrollHideDelay={100}>
            <Stack>
            {Object.entries(hypothesesDiagnostiques.hypotheses).map(
              ([key, value], index) => (
                <Card key={index} shadow="lg" radius="12px" style={{ marginBottom: "15" }}>
                  <div>
                    <Grid grow gutter="xs" justify="flex-start" align="center">
                      <Grid.Col span={1}>
                        <>
                          <RingProgress
                            size={90}
                            thickness={7}
                            roundCaps
                            sections={[
                              {
                                value: value.probabilité,
                                color: value.probabilité > 50 ? "lime" : "orange",
                              },
                            ]}
                            label={
                              <Text fw={700} ta="center" size="sm">
                                {value.probabilité}%
                              </Text>
                            }
                          />
                        </>
                      </Grid.Col>
                     
                      <Grid.Col span={9} ta="left" style={{ padding: 20 }}>
                        <Title size={19} style={{ marginBottom: "10" }}>
                          {key}
                        </Title>
                        <Text size="sm">
                          <i>{value.explain} </i>
                        </Text>
                        <Text size="sm" fw='bold' mt='md'>
                          <i>{value.action} </i>
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Stack align="stretch" justify="center" gap="md">
                          {/* <ExamensComplementairesButton maladie={key} /> */}
                          <PropositionsTherapeutiquesButton maladie={key} mondoCode={value.mondo_id}/>
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  </div>
                </Card>
              )
            )}
            </Stack>          
            </ScrollArea>
          </>

      )}
    </Stack>
  );
}
