import { useForm } from "@mantine/form";
import {
  TextInput,
  Textarea,
  Select,
  Checkbox,
  NumberInput,
  Button,
  Group,
  Fieldset,
  Card,
  Stack,
  Image,
  Text
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconAlertTriangleFilled, IconMail, IconPhone } from "@tabler/icons-react";
import { useStore } from "../Store";
import { useEffect } from "react";
import {client} from "../api/axiosClient"
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import brain from "../brain_ai_3.png";

export const NewPatientForm = () => {
  const {
    selectedPatientInfo,
    setSelectedPatientInfo,
  } = useStore();

  const birthDateString = selectedPatientInfo?.birthDate;
  let birthDate = "";

  if (birthDateString) {
    const [year, month, day] = birthDateString.split('-').map(Number);
    birthDate = new Date(Date.UTC(year, month - 1, day)); // Months are 0-indexed
  }

  const form = useForm({
    initialValues: {
      NomNaissance:  "",
      UsagePrenom: "",
      birthDate:"",
      gender:"",
      INS:"",
      NumIdentifiant:  "",
      Cle: "",
      OID: "",
      Email: "",
      Portable: "",
      Fixe: "",
      Adresse: "",
      CodePostale: "",
      Ville: "",
      commentaire: "",
      ListePrenom:"",
      maritalStatus: "",
      LieuNaissance: "",
      contact: "",
      generalPractitioner: "",
      multipleBirthBoolean: "",
      deceasedBoolean:"",
    },

  });

  useEffect(() => {
    const birthDateString = selectedPatientInfo?.birthDate;
    let birthDate = "";

    if (birthDateString) {
      const [year, month, day] = birthDateString.split('-').map(Number);
      birthDate = new Date(Date.UTC(year, month - 1, day));
    }

  }, [selectedPatientInfo]);
  
  const handleSubmit = async (values) => {
    try {

      const PatientFormData = {
        NomNaissance: values.NomNaissance || null,
        UsagePrenom: values.UsagePrenom || null,
        birthDate: values.birthDate
            ? new Date(values.birthDate).toISOString().split('T')[0]
            : null,
        gender: values.gender || null,
        INS: values.INS || null,
        NumIdentifiant: values.NumIdentifiant
            ? parseInt(values.NumIdentifiant, 10)
            : null,
        Cle: values.Cle ? parseInt(values.Cle, 10) : null,
        OID: values.OID || null,
        telecom: {
            Email: values.Email || null,
            Portable: values.Portable || null,
            Fixe: values.Fixe || null,
        },
        address: {
            Adresse: values.Adresse || null,
            CodePostale: values.CodePostale || null,
            Ville: values.Ville || null,
        },
        commentaire: values.commentaire || null,
        ListePrenom: values.ListePrenom || null,
        maritalStatus: values.maritalStatus || null,
        LieuNaissance: values.LieuNaissance
            ? parseInt(values.LieuNaissance, 10)
            : null,
        contact: [values.contact || null],
        generalPractitioner: values.generalPractitioner || null,
        multipleBirthBoolean: values.multipleBirthBoolean||false,
        deceasedBoolean: values.deceasedBoolean||false,
    };
    
      console.log(PatientFormData)

      
      const response = await client.post(
        `api/patients/`,
        PatientFormData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      

      if (response.status === 201) {
        setSelectedPatientInfo(response.data)
        notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Image
                  fit="contain"
                  src={brain}
                  alt="iAvicenne Logo"
                  width={25}
                  height={25}
                  style={{ margin: "0", display: "block" }}
                />
                <Text fw={700}>Nouveau Patient enregistré</Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: true,
            withCloseButton: true,
          });
        return true; // Indiquer que la sauvegarde a réussi
      } else {
        notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <IconAlertTriangleFilled color="red" size="25" />
                <Text fw={700}>Erreur</Text>
                <Text size="sm">
                "lors de la sauvegarde du formulaire données patient:"{response.status} {response.message} 
                </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: true,
            withCloseButton: true,
          });
       
        return false; // Indiquer que la sauvegarde a échoué
      }
    } catch (error) {
      console.log(error);
      notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <IconAlertTriangleFilled color="red" size="25" />
            <Text fw={700}>Erreur</Text>
            <Text size="sm">
            {error.message} 
            </Text>
          </Group>
        ),
        opacity: 0.7,
        autoClose: true,
        withCloseButton: true,
      });
   
      return false; // Indiquer que la sauvegarde a échoué
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Card radius="12px" withBorder={true}>
        <Stack>
          <Fieldset legend={<Text ta="start"  fw={700} size="md" >Identité du Patient</Text>} radius="12px" ta="left">
            <Group grow mb={5}>
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Nom</Text>}
                placeholder="Nom de naissance"
                {...form.getInputProps("NomNaissance")}
                required
              />

              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Prénom</Text>}
                placeholder="Prénom d'usage"
                {...form.getInputProps("UsagePrenom")}
                required
              />
              <DateInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Naissance</Text>}
                valueFormat="DD/MM/YYYY"
                placeholder="Date de Naissance"
                {...form.getInputProps("birthDate")}
                required
              />
            </Group>
            <Group grow mb={5}>
            <Select
              variant="filled"
              radius="12px"
              label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Genre</Text>}
              placeholder="Choisissez le genre"
              data={["M", "F"]}
              {...form.getInputProps("gender")}
              required
            />
            <NumberInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >INS</Text>}
                placeholder="Identifiant National de Santé"
                minLength={13}
                maxLength={13}
                {...form.getInputProps("INS")}
                hideControls
              />
              <NumberInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Lieu de Naissance</Text>}
                placeholder="Code du Lieu de naissance"
                {...form.getInputProps("LieuNaissance")}
                hideControls
              />
              </Group>
          </Fieldset>

          <Fieldset legend={<Text ta="start"  fw={700} size="md" >Sécurité Sociale</Text>} radius="12px" ta="left">
            <Group grow>
              <NumberInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >NIR</Text>}
                placeholder="NIR"
                minLength={13}
                maxLength={13}
                {...form.getInputProps("NumIdentifiant")}
                hideControls
              />
              <NumberInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Clé NIR</Text>}
                placeholder="Clé NIR"
                {...form.getInputProps("Cle")}
                minLength={2}
                maxLength={2}
                hideControls
              />
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >OID</Text>}
                placeholder="OID"
                {...form.getInputProps("OID")}
              />
            </Group>
          </Fieldset>
          <Fieldset legend={<Text ta="start"  fw={700} size="md" >Coordonnées du Patient</Text>} radius="12px" ta="left">
            <Group grow mb={5}>
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Email</Text>}
                placeholder="Email"
                leftSection={<IconMail size={16} />}
                {...form.getInputProps("Email")}
              />
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Portable</Text>}
                placeholder="Téléphone Portable"
                leftSection={<IconPhone size={16} />}
                {...form.getInputProps("Portable")}
              />
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Fixe</Text>}
                placeholder="Téléphone Fixe"
                leftSection={<IconPhone size={16} />}
                {...form.getInputProps("Fixe")}
              />
            </Group>

            <Group grow>
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Adresse</Text>}
                placeholder="N°, Rue, ..."
                {...form.getInputProps("Adresse")}
              />
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Code postale</Text>}
                placeholder="Code Postale"
                {...form.getInputProps("CodePostale")}
              />

              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Ville</Text>}
                placeholder="Ville"
                {...form.getInputProps("Ville")}
              />
            </Group>
          </Fieldset>
          <Fieldset legend={<Text ta="start"  fw={700} size="md" >Commentaire</Text>} radius="12px" ta="left">
            <Textarea
              variant="filled"
              radius="12px"
              placeholder={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Commentaire</Text>}
              {...form.getInputProps("commentaire")}
            />
          </Fieldset>
          <Fieldset legend={<Text ta="start"  fw={700} size="md" >Autres</Text>} radius="12px" ta="left">
            <Group grow mb={5}>
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Liste des prénoms</Text>}
                placeholder="Prénoms"
                {...form.getInputProps("ListePrenom")}
              />

              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Statut marital</Text>}
                placeholder="Statut marital"
                {...form.getInputProps("maritalStatus")}
              />

            </Group>

            <Group grow mb={15}>
              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Contact d'urgence</Text>}
                placeholder="Contact d'urgence"
                {...form.getInputProps("contact")}
              />

              <TextInput
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Médecin traitant</Text>}
                placeholder="Nom du médecin traitant"
                {...form.getInputProps("generalPractitioner")}
              />
            </Group>
            <Group>
              <Checkbox
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Naissance multiple</Text>}
                {...form.getInputProps("multipleBirthBoolean", {
                  type: "checkbox",
                })}
              />
              <Checkbox
                variant="filled"
                radius="12px"
                label={<Text ta="start" c="#94ADA7" fw={700} size="sm" >Décédé</Text>}
                {...form.getInputProps("deceasedBoolean", { type: "checkbox" })}
              />
            </Group>
          </Fieldset>

          <Group position="right" mt="md" grow>
            <Button variant="light" color="#94ADA7" radius="12px" type="submit">
              Valider
            </Button>
          </Group>
        </Stack>
      </Card>
    </form>
  );
};
