import { useStore } from "../Store";
import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { IconAi, IconBrain, IconCheck } from "@tabler/icons-react";
import { Button, Group, Image, Text, Loader } from "@mantine/core";
import { client } from "../api/axiosClient"
import brain from "../brain_ai_3.png";



export function StructureConsultData() {


  const {
    setCommandGetHypotheses,
    setCurrentNotification,
    consultationTranscription,
    setObservationValues,
    hpoList,
    setHpoList
  } = useStore();

  const fetchData = async () => {
    try {

      if (consultationTranscription) {
        notifications.clean()
        const notificationId = notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src={brain}
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: '0', display: 'block' }}
              />
              <Text fw={700}>Structuration en Cours</Text>
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            </Group>
          ),
          opacity: 0.7,
          autoClose: false,
          withCloseButton: true,
        });
        setCurrentNotification(notificationId);

        const response = await client.get(`api/structure/?transcription=${consultationTranscription}`)
        if (response.status === 200) {
          // / Get the transcription data
          const transcription = response.data.transcription
          setObservationValues(transcription)
          // console.log(transcription)
          // Properly serialize the transcription object for the API call
          const serializedTranscription = JSON.stringify(transcription)
         
          notifications.clean()
          const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Image
                  fit="contain"
                  src={brain}
                  alt="iAvicenne Logo"
                  width={25}
                  height={25}
                  style={{ margin: '0', display: 'block' }}
                />
                <Text fw={700}>Recherche termes HPO</Text>
                <Loader className="ChatLoader" color="#94ADA7" type="dots" />
              </Group>
            ),
            opacity: 0.7,
            autoClose: false,
            withCloseButton: true,
          });
          const hpo_list = await client.get(`api/iavicenne-memory/get-transciption-hpo-list/?transcription=${encodeURIComponent(serializedTranscription)}`)
          if (hpo_list.status === 200) {
            // Update the state with both transcription and HPO data
           
            if (hpo_list.data.hpo_terms && Array.isArray(hpo_list.data.hpo_terms)) {
              const newHpoTerms = hpo_list.data.hpo_terms;
              
              // Make sure hpoList is an array
              const currentHpoList = Array.isArray(hpoList) ? hpoList : [];
              
              // Combine old and new terms and remove duplicates using a simple object
              const uniqueHpo = {};
              [...currentHpoList, ...newHpoTerms].forEach(hpo => {
                if (hpo && hpo.hpo_id) {
                  uniqueHpo[hpo.hpo_id] = hpo;
                }
              });
              
              // Convert to array and sort
              const combinedHpoList = Object.values(uniqueHpo).sort((a, b) => 
                a.hpo_name.localeCompare(b.hpo_name)
              );
              
              setHpoList(combinedHpoList);
            }
            console.log(hpo_list)
            notifications.clean()
          }
         
          else {
            // Fall back to just setting the transcription if HPO data fetch fails
           console.log(hpo_list)
          }
          //  setObservationValues(response.data.transcription)
          //  if (response.data.transcription.Synthese.Resume.length>0){
          //   setCommandGetHypotheses(true)
          //  }
          // console.log(response.data.transcription)

        } else (console.log(response))

      }
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    fetchData();
  }, [consultationTranscription]);

}
