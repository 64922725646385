import React, { useState, useEffect } from 'react';
import { Loader, Group, Text, Image, Button,Tooltip } from "@mantine/core";
import { IconCheck, IconCopyPlusFilled, IconPillFilled, IconPlus } from "@tabler/icons-react";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import {client} from "../api/axiosClient"

export const RenewScanMedication = ({ documentId }) => {
  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const ordonnanceData = useStore((state) => state.ordonnanceData);
  const setOrdonnanceData = useStore((state) => state.setOrdonnanceData);
  const setSelectedMedicamentDetail = useStore(
    (state) => state.setSelectedMedicamentDetail
  );
  const { setActive, setActiveTab } = useStore();

  const [loading, setLoading] = useState(false);

  function convertToBoolean(ald) {
    if (ald === "true") {
      return true;
    } else if (ald === "false") {
      return false;
    } else {
      throw new Error("Must be a valid boolean.");
    }
  }
  
  async function getMedicaments(query, commentaire, ald) {
    const BooleenAld =convertToBoolean(ald)
    try {
      const response = await client.get(`api/cis-bdpm/chooseMedoc/?q=${query}`);
      
      if (response.status === 200) {
          console.log(response.data.medicament);
          addMedicament(response.data.medicament, commentaire, BooleenAld);
      } else {
          console.log("not found");
      }
  } catch (error) {
      if (error.response && error.response.status === 404) {
          console.log("Medicament not found, continuing...");
      } else {
          console.log("An error occurred:", error.message);
      }
  }
}
  


  const addMedicament = async (value, commentaire, ald) => {
    setLoading(true);
    const medicamentData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      medoc_id: value.id, 
      nom_commercial: value.denomination,
      dci: value.dci,
      dosage: value.dosage,
      forme: value.forme,
      voie: value.voie,
      commercialisation: value.commercialisation,
      ciscode: value.cisCode,
      matin: value.matin,
      midi: value.midi,
      soir: value.soir,
      coucher: value.coucher,
      periode: value.periode,
      nombre_periode: value.nombre_periode,
      commentaire:commentaire,
      tttAld:ald,
      tttFond:false,
      nsCIF:false,
      nsEFG: false,
      nsMTE: false,
      rcp: value.rcp,
    };

    try {
      if (Array.isArray(ordonnanceData)) {
        const existingIndex = ordonnanceData.findIndex(
          (item) => item.medoc_id === value.medoc_id
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                {/* <Image
                  fit="contain"
                  src={brain}
                  alt="iAvicenne Logo"
                  width={15}
                  height={15}
                  style={{ margin: "0", display: "block" }}
                /> */}
                <Text fw={700} size="xs">{value.nom_commercial} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
            "api/medication-requests/",
            medicamentData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            ordonnanceData.push({ ...medicamentData, id: response.data.id });
            setOrdonnanceData(ordonnanceData);
            console.log("Ordonnance mise à jour");
            // console.log(ordonnanceData);
    
           
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/documents/${documentId}/`);

      if (response.status === 200) {
       
        const ordonnance = response.data.metadata.document_info.ordonnance;
        console.log(ordonnance)
        
    // Utilisez Object.entries() pour parcourir les paires clé-valeur de l'objet ordonnance
    for (const [medicament, details] of Object.entries(ordonnance)) {
      // console.log(`Médicament: ${medicament}, Posologie: ${details.posologie}, ALD: ${details.ald}`);
      // await addMedicament(medicament, details.posologie, details.ald); // Ajustez cette ligne selon votre fonction addMedicament
      await getMedicaments(medicament, details.posologie,details.ald)}
      
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler l'ordonnance
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="12px"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton_2'
      color="#94ADA7"
      radius="12px"
      size="compact-xs"
      onClick={() => {
       
        renouvelerOrdo();
        setActive("medicaments")
        setActiveTab("medicaments")
      }} 
      loading={loading} 
      disabled={loading}
    >
       <IconPlus size={12}/>
       <IconPillFilled size={14}/>
    
    </Button>
    </Tooltip>
   
 
  );
};

