import { useEffect, useState, useCallback } from "react";
import {
  Card,
  Text,
  Stack,
  Alert,
  Group,
  Indicator,
  Grid,
  Divider,
  ScrollArea,
  Fieldset,
  Button,
  Table,
  Skeleton
} from "@mantine/core";
import { useStore } from "../Store";
import { format, parseISO, set } from "date-fns";
import { fr } from "date-fns/locale";
import { client } from "../api/axiosClient";
import {
  IconAlertTriangleFilled,
  IconCopyCheckFilled,
  IconCopyXFilled,
  IconDropletFilled,
  IconFileTypePdf,
  IconMailFilled,
  IconMailOpenedFilled,

} from "@tabler/icons-react";
import { TypographyStylesProvider } from "@mantine/core";
import { AddPatientFromMail } from "./AddNewPatientFromMail";
import { SearchPatientRapprochement } from "./SearchPatientRapprochement";
import DOMPurify from "dompurify";

const formatDate = (dateString) => {
  if (!dateString) return "Date non définie";

  const now = new Date();
  const dateToFormat = parseISO(dateString);

  if (now.toDateString() === dateToFormat.toDateString()) return "Aujourd'hui";

  return format(dateToFormat, "dd/MM/yyyy", { locale: fr });
};

const formatDate2 = (dateString) => {
  if (!dateString) return "Date non définie";

  const now = new Date();
  const dateToFormat = parseISO(dateString);

  if (now.toDateString() === dateToFormat.toDateString()) return "Aujourd'hui";

  return format(dateToFormat, "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
};

const formatDate4 = (dateString) => {
  if (!dateString) return "Date non définie";
  const dateToFormat = parseISO(dateString);

  return format(dateToFormat, "yyyy-MM-dd");
};

const extractBiologieValue = (inputString) => {
  const parts = inputString.split("[");
  return parts[0];
};

const formatNumber = (num) => {
  return parseFloat(num).toString();
};

const formatDate3 = (dateString) => {
  console.log(dateString);
  if (dateString) {
    const date = new Date(
      dateString.slice(0, 4),
      dateString.slice(4, 6) - 1,
      dateString.slice(6, 8),
      dateString.slice(8, 10),
      dateString.slice(10, 12)
    );
    return (
      <>
        {date.toLocaleDateString("fr-FR", {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </>
    );
  }
};

export function Mailbox() {
  // const email = "899700578851@mail-tests-editeur.espacedeconfiance.mssante.fr";

 
  const { mailboxData, setMailboxData, practitioner } = useStore();
  const email = practitioner.mailbox_mss;
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [disabledEmails, setDisabledEmails] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // console.log({ selectedEmail: selectedEmail });
  useEffect(() => {
    // Simulons un appel API pour charger les données
    const fetchData = async () => {
      try {
        // Simule un délai pour le chargement des données
        await new Promise((resolve) => setTimeout(resolve, 500));
        // Met à jour les données ici si nécessaire
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      } finally {
        // Une fois toutes les données chargées, on désactive le mode "loading"
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const fetchMails = useCallback(async () => {
    //   // Deuxième fetch (api/mssante/fetchBAL)
    //   try {
    //     const response2 = await client.get(
    //       // `api/mssante/fetchBAL/?email=${email}`
    //       // `api/mssante/`
    //     );
    //     console.log({ response2: response2 });

    //     if (response2.status === 200) {
    //       // setMailboxData(prevData => ({
    //       //   ...prevData,
    //       //   email_list: [...prevData.email_list, ...(response2.data.email_list || [])]
    //       // }));
    //       setError(null);
    //     }
    //   } catch (error2) {
    //     console.error("Error fetching BAL:", error2);
    //     // Gérer l'erreur spécifique au deuxième fetch si nécessaire
    //   }
    try {
      // Premier fetch (api/mssante)
      const response1 = await client.get(
        `api/mssante/?practitioner=67&&limit=50`
      );
      console.log({ response1: response1 });

      if (response1.status === 200) {
        // setMailboxData(prevData => ({
        //   ...prevData,
        //   email_list: response1.data
        // }));
        setMailboxData({ email_list: response1.data.results });
        setSelectedEmail(response1.data.results[0]);
      }

      setError(null);
      console.log({ mailboxData: mailboxData });
    } catch (error1) {
      console.error("Error fetching mails from database:", error1);
      setError(
        error1.response?.data ||
          error1.message ||
          "An error occurred while fetching mails from database"
      );
    }
  }, [client, email, setMailboxData, setError]);

  useEffect(() => {
    fetchMails();
  }, [fetchMails]);

  const HandleEmailClick = (emailItem) => {
    setSelectedEmail(emailItem);

    // Gérer l'état désactivé pour chaque email individuel
    setDisabledEmails((prevState) => ({
      ...prevState,
      [emailItem.id]: true, // Désactiver l'email cliqué
    }));

    try {
      PatchMailData(emailItem);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'email :", error);
    }
  };

  const PatchMailData = async (emailItem) => {
    const newData = {
      ...emailItem,
      is_read: true,
    };
    try {
      const response = await client.put(
        `api/mssante/${emailItem.id}/`,
        newData
      );
      if (response.status !== 200) {
        throw new Error("La mise à jour a échoué.");
      }
    } catch (error) {
      throw new Error(
        "Erreur lors de la mise à jour des données : " + error.message
      );
    }
  };

  const get = (obj, path, defaultValue = undefined) => {
    const travel = (regexp) =>
      String.prototype.split
        .call(path, regexp)
        .filter(Boolean)
        .reduce(
          (res, key) => (res !== null && res !== undefined ? res[key] : res),
          obj
        );
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === null ? defaultValue : result;
  };

  const getValue = (value) => {
    if (!value) return "";
    return value["@value"] || value["#text"] || "";
  };

  const findAllObservations = (obj) => {
    let results = [];

    const search = (current) => {
      if (!current) return;

      if (current.observation) {
        results.push(current.observation);
      }

      if (typeof current === "object") {
        Object.values(current).forEach((value) => {
          if (Array.isArray(value)) {
            value.forEach((item) => search(item));
          } else {
            search(value);
          }
        });
      }
    };

    search(obj);
    return results;
  };
  const extractDocumentId = (path) => {
    // Utilise une regex pour trouver l'ID dans le path
    const matches = path.match(/documents\/(\d+)/);
    // Retourne l'ID trouvé ou null si pas trouvé
    return matches ? matches[1] : null;
  };

  return (
    <Grid>
      <Grid.Col span={4} className="MailsGridCol">
        <Divider my="xs" label="Liste des Messages" labelPosition="center" />
        <ScrollArea
          h="88vh"
          w="100%"
          offsetScrollbars
          scrollHideDelay={100}
          scrollbarSize={8}
          style={{ translate: "25px" }}
        >
          <Stack gap="xs">
            {mailboxData.email_list
              ?.sort((a, b) => new Date(b.date) - new Date(a.date))
              ?.map((emailItem) => (
                <Indicator
                  key={emailItem.hash}
                  position="middle-end"
                  disabled={disabledEmails[emailItem.id] || emailItem.is_read} // Désactiver si dans l'état
                  size={18}
                  withBorder
                  style={{ marginRight: 15 }}
                >
                  <Card
                    // className="mailButton"
                    shadow="lg"
                    radius="12px"
                    onClick={() => HandleEmailClick(emailItem)}
                    style={{
                      cursor: "pointer",
                      border:
                        selectedEmail?.id === emailItem.id
                          ? "3px solid #94ADA7"
                          : "3px solid #fff",
                    }}
                  > <Skeleton height={"100%"} radius="12px" visible={loading}>
                    <Group justify="space-between">
                   

                      <Group gap="xs">
                        {emailItem.sender.includes("DGS-URGENT") ? (
                          <IconAlertTriangleFilled
                            color="#94ADA7"
                            size="18px"
                          />
                        ) : emailItem.subject.includes("HNET.1/MSG/") ? (
                          <IconDropletFilled color="#f03e3eab" size="25px" />
                        ) : selectedEmail?.id === emailItem.id ? (
                          <IconMailOpenedFilled color="#e4b400ad" size="25px" />
                        ) : (
                          <IconMailFilled color="#e4b400ad" size="25px" />
                        )}
                        <Text
                          size="sm"
                          ta="left"
                          fw={700}
                          truncate="end"
                          w="15vw"
                        >
                          {emailItem.sender}
                        </Text>
                      </Group>
                     
                      <Group gap="xs">
                        {emailItem.attachments.alert && (
                          <IconAlertTriangleFilled color={emailItem.alert} />
                        )}
                        {emailItem.attachments.length && (
                          <IconFileTypePdf color="#fa5252" size="17px" />
                        )}

                        {!emailItem.sender.includes("DGS-URGENT") &&
                          (emailItem.patient ? (
                            <IconCopyCheckFilled color="#40c057" size="17px" />
                          ) : (
                            <IconCopyXFilled color="#fa5252" size="17px" />
                          ))}

                        <Text size="md" ta="right">
                          {formatDate(emailItem.date)}
                        </Text>
                      </Group>
                    </Group>
                    </Skeleton>
                    <Skeleton height={"100%"} mt={10} radius="12px" visible={loading}>
                    <Text size="sm" ta="left" lineClamp={1}>
                      {emailItem.subject}
                    </Text>
                    {/* <Text size="sm" ta="left" c="gray" truncate="end" w="20vw">
                      {emailItem.content}
                    </Text> */}
                    </Skeleton>
                  </Card>
                </Indicator>
              ))}
          </Stack>
        </ScrollArea>
      </Grid.Col>
      <Grid.Col span={8} className="MailsGridCol">
        <Divider my="xs" label="Contenu du Message" labelPosition="center" />
        {error && <Alert color="red">{error}</Alert>}
        <Card shadow="lg" radius="12px" padding="lg" style={{ height: "100%" }}>
          {selectedEmail && (
            <Stack gap="xs">
              {/* <Grid>
                <Grid.Col span={8}> */}
              <Fieldset
                legend={
                  <Group gap="xs">
                    <Text size="sm" ta="left" fw={700} c="gray">
                      Message de
                    </Text>
                    <Text size="sm" ta="left" fw={700} c="gray">
                      {selectedEmail.sender}
                    </Text>
                    <Text size="sm" ta="left" fw={700} c="gray">
                      le {formatDate2(selectedEmail.date)}
                    </Text>
                  </Group>
                }
                radius="12px"
                ta="left"
              >
                <Skeleton height={"100%"} radius="12px" visible={loading}>
                <Group gap="xs">
                  <Text size="sm" ta="left" fw={700}>
                    Sujet:
                  </Text>
                  <Text size="sm" ta="left">
                    {selectedEmail.subject}
                  </Text>

                  {selectedEmail.attachments.length > 0 &&
                    selectedEmail.attachments?.map?.(
                      (document, index) =>
                        document.path && (
                          <Group gap="xs">
                            <Divider orientation="vertical" />
                            <Text size="sm" ta="left" fw={700}>
                              Pièces Jointes:
                            </Text>
                            <a
                              key={index}
                              href={`https://www.iavicenne.fr/${document.path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconFileTypePdf color="#fa5252" size="25px" />
                            </a>
                          </Group>
                        )
                    )}
                </Group>
                </Skeleton>
              </Fieldset>
              {/* </Grid.Col>
                <Grid.Col span={4}> */}
              {selectedEmail.attachments.length > 0 &&
                selectedEmail.attachments.map((document, index) => (
                  <Fieldset
                    legend={
                      <Text size="sm" ta="left" fw={700} c="gray">
                        Identité du Patient
                      </Text>
                    }
                    radius="12px"
                    ta="left"
                  >
                    <Skeleton height={"100%"} radius="12px" visible={loading}>
                    <Group gap="xs">
                      {document.metadata?.ClinicalDocument?.recordTarget
                        ?.patientRole?.id?.["@root"] ===
                        "1.2.250.1.213.1.4.8" ||
                        ("1.2.250.1.213.1.4.10" && (
                          <Group>
                            <Text size="sm" ta="left" fw={700}>
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.id[0]?.[
                                  "@extension"
                                ]
                              }
                            </Text>
                          </Group>
                        ))}
                      {document.metadata?.ClinicalDocument && (
                        <Text size="sm" ta="left" fw={700}>
                          {document.metadata?.ClinicalDocument?.recordTarget?.patientRole?.patient?.name?.given
                            ?.map?.((item) =>
                              item["@qualifier"] === "CL" ? item["#text"] : null
                            )
                            .filter(Boolean)}{" "}
                          {document.metadata?.ClinicalDocument?.recordTarget?.patientRole?.patient?.name?.family
                            ?.map?.((item) =>
                              item["@qualifier"] === "CL" ? item["#text"] : null
                            )
                            .filter(Boolean)}{" "}
                          {formatDate(
                            document.metadata?.ClinicalDocument?.recordTarget
                              ?.patientRole?.patient.birthTime["@value"]
                          )}{" "}
                          {
                            document.metadata?.ClinicalDocument?.recordTarget
                              ?.patientRole?.patient.administrativeGenderCode[
                              "@code"
                            ]
                          }
                        </Text>
                      )}
                      <Divider orientation="vertical" />

                      {selectedEmail.patient ? (
                        <Group gap="xs">
                          <IconCopyCheckFilled color="#40c057" />
                          <Text c="#40c057" size="xs" fw={600}>
                            Rapprochement Fait avec succès
                          </Text>
                        </Group>
                      ) : (
                        <Group gap="xs">
                          <IconCopyXFilled color="#fa5252" />
                          <Text c="#fa5252" size="xs" fw={600}>
                            Echec du Rapprochement
                          </Text>
                          <Divider orientation="vertical" />
                          <SearchPatientRapprochement
                            documentId={
                              document?.path
                                ? extractDocumentId(document.path)
                                : null
                            }
                            mailId={selectedEmail?.id}
                          />

                          <AddPatientFromMail
                            values={{
                              NomNaissance:
                                document.metadata?.ClinicalDocument?.recordTarget?.patientRole?.patient?.name?.given
                                  ?.map((item) =>
                                    item["@qualifier"] === "CL"
                                      ? item["#text"]
                                      : null
                                  )
                                  .filter(Boolean)[0] || "",

                              UsagePrenom:
                                document.metadata?.ClinicalDocument?.recordTarget?.patientRole?.patient?.name?.family
                                  ?.map((item) =>
                                    item["@qualifier"] === "CL"
                                      ? item["#text"]
                                      : null
                                  )
                                  .filter(Boolean)[0] || "",

                              birthDate: document.metadata?.ClinicalDocument
                                ?.recordTarget?.patientRole?.patient
                                ?.birthTime?.["@value"]
                                ? formatDate4(
                                    document.metadata.ClinicalDocument
                                      .recordTarget.patientRole.patient
                                      .birthTime["@value"]
                                  )
                                : null,

                              gender:
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient
                                  ?.administrativeGenderCode?.["@code"] || "",

                              INS: (() => {
                                const ids =
                                  document.metadata?.ClinicalDocument
                                    ?.recordTarget?.patientRole?.id;
                                if (!Array.isArray(ids)) return "";

                                const insId = ids.find(
                                  (id) =>
                                    id["@root"] === "1.2.250.1.213.1.4.8" ||
                                    id["@root"] === "1.2.250.1.213.1.4.10"
                                );

                                return insId ? insId["@extension"] : "";
                              })(),
                            }}
                          />
                        </Group>
                      )}
                    </Group>
                    </Skeleton>
                  </Fieldset>
                ))}
              {/* </Grid.Col>
              </Grid> */}
              {/* <Divider my="xs" labelPosition="center" /> */}
              <ScrollArea
                h="77vh"
                w="100%"
                offsetScrollbars
                scrollHideDelay={100}
                scrollbarSize={8}
              >
                {selectedEmail.subject.includes("HNET.1/MSG/") &&
                  selectedEmail.attachments.length > 0 &&
                  selectedEmail.attachments?.map?.(
                    (document, documentIndex) => {
                      const observations = findAllObservations(
                        document?.metadata?.ClinicalDocument
                      );

                      return (
                        <>
                          <Fieldset
                            legend={
                              <Text fw={700} size="sm" ta="left" c="gray">
                                {
                                  document.metadata?.ClinicalDocument?.title?.[
                                    "#text"
                                  ]
                                }{" "}
                                du{" "}
                                {formatDate3(
                                  document.metadata?.ClinicalDocument?.author
                                    ?.time["@value"]
                                )}
                              </Text>
                            }
                            radius="12px"
                            ta="left"
                          >                <Skeleton height={"100%"} radius="12px" visible={loading}>

                            <Table
                              stickyHeader
                              highlightOnHover
                              striped
                              withRowBorders={false}
                              horizontalSpacing="xl"
                            >
                              <Table.Thead>
                                <Table.Tr>
                                  <Table.Th
                                    size="sm"
                                    ta="left"
                                    fw={600}
                                    c="grey"
                                    style={{ width: "15vw" }}
                                  >
                                    Examen
                                  </Table.Th>
                                  <Table.Th
                                    size="sm"
                                    ta="left"
                                    fw={600}
                                    c="grey"
                                    style={{ width: "2vw" }}
                                  >
                                    Valeur
                                  </Table.Th>
                                  <Table.Th
                                    size="sm"
                                    ta="left"
                                    fw={600}
                                    c="grey"
                                    style={{ width: "10vw" }}
                                  >
                                    Unités
                                  </Table.Th>
                                  <Table.Th
                                    size="sm"
                                    ta="left"
                                    fw={600}
                                    c="grey"
                                    style={{ width: "25vw" }}
                                  >
                                    Valeur Normale
                                  </Table.Th>
                                </Table.Tr>
                              </Table.Thead>
                              <Table.Tbody>
                                {observations.map((observation, index) => (
                                  <Table.Tr key={index}>
                                    <Table.Td>
                                      <Text size="sm" ta="left" fw={600}>
                                        {extractBiologieValue(
                                          get(observation, "code.@displayName")
                                        )}
                                      </Text>
                                    </Table.Td>
                                    <Table.Td>
                                      {get(
                                        observation,
                                        "interpretationCode.@code"
                                      ) === "N" ? (
                                        <Button variant="subtle" ta="left">
                                          <Text size="sm" ta="left" c="black">
                                            {getValue(observation.value)}
                                          </Text>
                                        </Button>
                                      ) : get(
                                          observation,
                                          "interpretationCode.@code"
                                        ) === "HH" ||
                                        get(
                                          observation,
                                          "interpretationCode.@code"
                                        ) === "LL" ? (
                                        <Button
                                          variant="filled"
                                          color="red"
                                          ta="left"
                                        >
                                          <Text size="sm" ta="left" c="white">
                                            {getValue(observation.value)}
                                          </Text>
                                        </Button>
                                      ) : get(
                                          observation,
                                          "interpretationCode.@code"
                                        ) === "H" ||
                                        get(
                                          observation,
                                          "interpretationCode.@code"
                                        ) === "L" ? (
                                        <Button
                                          variant="light"
                                          color="#f59f00"
                                          ta="left"
                                        >
                                          <Text size="sm" ta="left" c="orange">
                                            {getValue(observation.value)}
                                          </Text>
                                        </Button>
                                      ) : (
                                        <Button variant="subtle" ta="left">
                                          <Text size="sm" ta="left" c="black">
                                            {getValue(observation.value)}
                                          </Text>
                                        </Button>
                                      )}
                                    </Table.Td>
                                    <Table.Td ta="left">
                                      {get(observation, "value.@unit")}
                                    </Table.Td>
                                    <Table.Td ta="left">
                                      <Group gap="xs">
                                        <Text size="sm" ta="left">
                                          {formatNumber(
                                            get(
                                              observation,
                                              "referenceRange.observationRange.value.low.@value"
                                            )
                                          )}
                                        </Text>
                                        {"-"}
                                        <Text size="sm" ta="left">
                                          {formatNumber(
                                            get(
                                              observation,
                                              "referenceRange.observationRange.value.high.@value"
                                            )
                                          )}{" "}
                                          {get(
                                            observation,
                                            "referenceRange.observationRange.value.high.@unit"
                                          )}
                                        </Text>
                                      </Group>
                                    </Table.Td>
                                  </Table.Tr>
                                ))}
                              </Table.Tbody>
                            </Table>
                            </Skeleton>
                          </Fieldset>
                          <Divider style={{ margin: "20px" }} />
                        </>
                      );
                    }
                  )}

                {selectedEmail.content.HTMLContent.length > 0 ? (
                  <Text size="sm" ta="left">
                    <TypographyStylesProvider>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            selectedEmail.content.HTMLContent
                          ),
                        }}
                      />
                    </TypographyStylesProvider>
                  </Text>
                ) : (
                  selectedEmail.content.TextContent.length > 0 && (
                    <Text size="sm" ta="left">
                      {DOMPurify.sanitize(selectedEmail.content.TextContent)}
                    </Text>
                  )
                )}

                {selectedEmail.attachments?.map?.((document, index) => {
                  const path = document.path;
                  if (!path || typeof path !== "string") {
                    return null;
                  }

                  return (
                    <>
                      <Divider style={{ margin: "20px" }} />

                      <iframe
                        key={index}
                        src={`https://www.iavicenne.fr/${path}`}
                        width="100%"
                        style={{
                          borderRadius: "16px",
                          height: "100vh",
                          marginTop: "15px",
                        }}
                        // sandbox="allow-same-origin allow-scripts"
                        title={`attachment-${index}`}
                      />
                    </>
                  );
                })}
              </ScrollArea>
            </Stack>
          )}
        </Card>
      </Grid.Col>
    </Grid>
  );
}
