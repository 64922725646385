import React, {useState} from "react";
import logo from "../logoPSC.svg";
import { Button, Text, Stack, Card } from "@mantine/core";
import { IconArrowDown, IconRotateClockwise } from "@tabler/icons-react";
import { LoginComponent } from "./Login2FA";
import { useToggle } from "@mantine/hooks";


export function Login() {
  const [loading, setLoading] = useState(false);
  const [alternative, toggle] = useToggle();
  return (
    <Card className="loginCard" >
      <Stack align="center" justify="stretch" gap="xs" size={16}>
        <Text className="loginTitle" c="#94ADA7" fw={500} style={{fontSize:33, fontFamily:"Palatino"}}>
          Connexion
        </Text>
        {/* <IconArrowDown color="#94ADA7" size={16} className="arrowLogin"/> */}
        <Button
          className="PSC-button"
          variant="outline"
          radius="12px"
          color="#94ADA7"
          loading={loading}
        >
          <div>
            <a href="https://wallet.esw.esante.gouv.fr/auth/?response_type=code&client_id=iavicenne&redirect_uri=https://www.iavicenne.fr/api/auth-redirect/&scope=openid%20scope_all&acr_values=eidas1">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </div>
        </Button>
        <Button
                     
                      className="PSC-button"
                      variant="subtle"
                      size="md"
                      color="#94ADA7"
                      radius="12px"
                     
                      onClick={() => toggle()}
             
                    ><Text c="#94ADA7" fw={500} style={{fontSize:15, fontFamily:"Palatino"}} >Pro Santé Connect ne fonctionne pas ?</Text>
                     {/* <IconRotateClockwise size="18px"/> */}
                    </Button>
        {alternative &&(
          <LoginComponent/>
        )}
      </Stack>
    </Card>
  );
}


