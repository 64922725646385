// App.js

import {
  Stack,
  Button,
  Grid,
  Text,
  Title,
  Card,
  Group,
  Fieldset,
  Indicator,
  Divider,
  Skeleton,
  Tabs,
  SegmentedControl,
  Blockquote,
  Code,
} from "@mantine/core";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import { useState, useEffect } from "react";
import {
  IconCrown,
  IconCurrencyEuro,
  IconStar,
  IconCopy,
  IconCheck,
} from "@tabler/icons-react";
import { QRCodeCanvas } from "qrcode.react";
import { CopyButton, ActionIcon, Tooltip, rem } from "@mantine/core";

export function Abonnements() {
  const [activeTabAbonnement, setActiveTabAbonnement] = useState("Ambassadeur");
  const [activeAbonnement, setActiveAbonnement] = useState("Ambassadeur");
  const { practitioner, ordonnanceEntete, currentUser } = useStore();
  const [loading, setLoading] = useState(false);

  const handleAbonnementControlChange = (value) => {
    setActiveAbonnement(value);
    setActiveTabAbonnement(value);
  };
  const dataAbonnement = [
    {
      value: "Mensuel",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconCurrencyEuro size="16" />
          <Text size="xs">Mensuel</Text>
        </Group>
      ),
    },
    {
      value: "Annuel",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconCurrencyEuro size="16" />
          <Text size="xs">Annuel</Text>
        </Group>
      ),
    },
    {
      value: "Ambassadeur",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconStar size="16" />
          <Text size="xs">Ambassadeur</Text>
        </Group>
      ),
    },
    {
      value: "Investisseur",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconCrown size="16" />
          <Text size="xs">Investisseur</Text>
        </Group>
      ),
    },
  ];
  return (
    <Tabs
      value={activeTabAbonnement}
      onChange={setActiveTabAbonnement}
      radius="12px"
    >
      <SegmentedControl
        data={dataAbonnement}
        value={activeAbonnement}
        onChange={handleAbonnementControlChange}
        color="#94ADA7"
        radius="12px"
        size="xs"
        withItemsBorders={false}
        transitionDuration={500}
        transitionTimingFunction="linear"
        fullWidth
        style={{ marginBottom: 20 }}
      />

      <Tabs.Panel value="Annuel">
        <Skeleton height={"100%"} radius="12px" visible={loading}>
          <Stack gap="md" justify="stretch" align="center">
            <Text size="xs">
              Scannez ce code QR pour afficher le lien sur votre mobile
            </Text>

            <Card withBorder w="max-content" mb={20} shadow>
              <QRCodeCanvas
                value={`https://buy.stripe.com/cN23cn93g4qDgVOaEG?client_reference_id=7860${practitioner.id}`}
                size={150}
                minVersion={10}
              />
            </Card>
          </Stack>
          <Indicator label="-10 %" size={22} withBorder color="#94ADA7">
            <Button
              className="printButton"
              variant="light"
              size="md"
              color="#94ADA7"
              radius="12px"
              fullWidth
            >
              <a
                href={`https://buy.stripe.com/cN23cn93g4qDgVOaEG?client_reference_id=7860${practitioner.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Abonnement Annuel  {" "}
              </a>
            </Button>
          </Indicator>
        </Skeleton>
      </Tabs.Panel>
      <Tabs.Panel value="Mensuel">
        <Skeleton height={"100%"} radius="12px" visible={loading}>
          <Stack gap="md" justify="stretch" align="center">
            <Text size="xs">
              Scannez ce code QR pour afficher le lien sur votre mobile
            </Text>

            <Card withBorder w="max-content" mb={20} shadow>
              <QRCodeCanvas
                value={`https://buy.stripe.com/28o3cnbbo0aneNG4gj?client_reference_id=7860${practitioner.id}`}
                size={150}
                minVersion={10}
              />
            </Card>
          </Stack>
          <Button
            className="printButton"
            variant="light"
            size="md"
            color="#94ADA7"
            radius="12px"
            fullWidth
          >
            <a
              href={`https://buy.stripe.com/28o3cnbbo0aneNG4gj?client_reference_id=7860${practitioner.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Abonnement Mensuel {" "}
            </a>
          </Button>
        </Skeleton>
      </Tabs.Panel>
      <Tabs.Panel value="Ambassadeur">
        <Blockquote
          color="#94ADA7"
          iconSize={43}
          icon={<IconStar />}
          radius="lg"
        >
          <Title order={4} mb={10}>
            Votre Abonnement gratuit pendant un an
          </Title>
          <Text fs="italic">
            Si dix de vos confrères souscrivent à un abonnement annuel avec
            votre code Ambassadeur.
          </Text>
        </Blockquote>
        <Blockquote
          color="#94ADA7"
          iconSize={43}
          //   cite={`– Votre code ambsasadeur: AMBASSADEUR${currentUser.username}`}

          radius="lg"
        >
          <Group gap="xs">
            <Text c="dimmed">Votre code ambsasadeur:</Text>
            <Text fs={800}>PARRAINAGE{currentUser.username}</Text>
            <CopyButton
              value={`PARRAINAGE${currentUser.username}`}
              timeout={2000}
            >
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copié" : "Copier"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={copy}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Blockquote>
      </Tabs.Panel>
      <Tabs.Panel value="Investisseur">
        <Blockquote
          color="#94ADA7"
          iconSize={43}
          cite="– Dr O. Harit / Président Fondateur"
          icon={<IconCrown />}
          radius="lg"
        >
          <Text fs="italic" mb={5}>
            " Chères Consoeurs, Cher Confrères,
          </Text>

          <Text fs="italic"  mb={5}>
            Aujourd'hui, grâce aux progrès
            dans le domaine de l'iA une nouvelle révolution se profile.
            C'est l'occasion pour nous de nous approprier ce sujet et de
            maîtriser ces outils qui sont devenus essentiels dans notre pratique
            quotidienne.
          </Text>
    
          <Text fs="italic">
            Vous avez l'opportunité d'être les pionniers dans cette avancée majeure de la médecine."
          </Text>
        </Blockquote>
        <Button
          // className="printButton"
          variant="light"
          size="sm"
          color="#94ADA7"
          radius="12px"
          fullWidth
          mt="sm"
          disabled
        >
          Investir
        </Button>
      </Tabs.Panel>
    </Tabs>
  );
}
