import React, { useState, useEffect, useRef, useCallback } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import Markdown from "react-markdown";
import { Dialog, Group, Text, Loader, Center, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { IconCheck } from "@tabler/icons-react";
import {client} from "../api/axiosClient"
import {AudioVisualizer} from "./Audiovisualizer";
import brain from "../brain_ai_3.png";



const StreamComponent = ({ url }) => {
  const [fullText, setFullText] = useState("");
  const [visibleChars, setVisibleChars] = useState(0);
  const [error, setError] = useState(null);
  const { opened, open, close } = useStore();

  const {
    setConsultationTranscription,
    loading,
    setLoading,
    currentNotification,
  } =  useStore();

  const textContainerRef = useRef(null);
  const scrollRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const viewport = scrollContainer.querySelector('.mantine-ScrollArea-viewport');
      if (viewport) {
        viewport.scrollTo({
          top: viewport.scrollHeight,
          behavior: 'auto'
        });
      }
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setError(null);
      setFullText("");
      setVisibleChars(0);
      open(); // Ouvrir le dialogue au début du chargement

      try {
        const response = await fetch(url, { signal });

        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        let accumulatedText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          if (isMounted) {
            accumulatedText += value;
            setFullText((prev) => prev + value);
          }
        }
        setConsultationTranscription(accumulatedText);
        setLoading(false);
      } catch (error) {
        if (error.name !== "AbortError" && isMounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    fetchData();

    // Afficher la notification de chargement
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <Image
            fit="contain"
            src={brain}
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>Transcription Terminée</Text>
          <IconCheck
            style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
          />
        </Group>
      ),

      opacity: 0.7,
      autoClose: 1000,
      withCloseButton: true,
    });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url, open, setConsultationTranscription]);

  useEffect(() => {
    if (visibleChars < fullText.length) {
      const timer = setTimeout(() => {
        setVisibleChars((prev) => Math.min(prev + 1, fullText.length));
      }, 3);

      return () => clearTimeout(timer);
    }
  }, [fullText, visibleChars]);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const observer = new MutationObserver(() => {
      scrollToBottom();
    });

    observer.observe(scrollContainer, {
      childList: true,
      subtree: true,
      characterData: true
    });

    return () => observer.disconnect();
  }, [scrollToBottom]);

  useEffect(() => {
    scrollToBottom();
  }, [visibleChars, scrollToBottom]);

  const visibleText = fullText.slice(0, visibleChars);

  return (
    <>
     <div ref={scrollRef}>
      <Dialog
        className="transcriptionDialog"
        opened={opened}
        withCloseButton
        onClose={close}
        radius="12px"
        withBorder={true}
      >
        <Text 
          size="sm" 
          mb="xs" 
          fw={500} 
          ta="left"
          style={{ maxHeight: '60vh', overflowY: 'auto', scrollbarWidth: 'none' }}
          ref={textContainerRef}
        >
          {loading && (
            <Center>
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            </Center>
          )}
          {error && <p>Error: {error.message}</p>}
          <Markdown>{visibleText}</Markdown>{" "}
        </Text>
      </Dialog>
      </div>
    </>
  );
};

export function RecordConsultation() {
  const [streamUrl, setStreamUrl] = useState("");
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false)

  const {
    commandRecordConsult,
    setCommandRecordConsult,
    commandSaveRecordConsult,
    setCommandSaveRecordConsult,
    loading,
    setCurrentNotification,
    currentSessionInfo
  } = useStore();

  const audioRecorderRef = useRef(null);
 
   // On utilise le hook "useAudioRecorder" pour avoir le contrôle total
   const recorderControls = useAudioRecorder({
    // Options possibles :
    audioTrackConstraints: {
      noiseSuppression: true,
      echoCancellation: true,
    },
    // Appelé si l'utilisateur refuse l'accès au micro
    onNotAllowedOrFound: (err) => {
      console.error("Erreur micro :", err);
    },
  });
  

  useEffect(() => {
    // Demande d'accès au micro
    const getMicrophone = async () => {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(audioStream);
      } catch (err) {
        console.error("Erreur lors de l'accès au micro :", err);
      }
    };

    getMicrophone();

    return () => {
      if (stream) {
        // Arrêt du stream lorsque le composant est démonté
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  

  useEffect(() => {
    const element = audioRecorderRef.current;
    if (element) {
      const handleClick = () => {
        setIsRecording((prev) => !prev);
      };

      // Ajout de l'event listener
      element.addEventListener("click", handleClick);

      // Cleanup : suppression de l'event listener
      return () => {
        element.removeEventListener("click", handleClick);
      };
    }
  }, []);

  useEffect(() => {
    if (commandRecordConsult) {
      // On démarre l'enregistrement
      recorderControls.startRecording();
      setCommandRecordConsult(false)
      setIsRecording(true);
    }
    if (commandSaveRecordConsult) {
      recorderControls.stopRecording();
      setCommandSaveRecordConsult(false);
      setIsRecording(false);
    }
  }, [commandRecordConsult,commandSaveRecordConsult]);

  const uploadAudioBlob = async (blob) => {
    // Déterminer l'extension du fichier en fonction du type MIME du blob
    const mimeType = blob.type;
    let fileExtension = "";

    if (mimeType === "audio/webm") {
      fileExtension = "webm";
    } else if (mimeType === "audio/wav") {
      fileExtension = "wav";
    } else if (mimeType === "audio/mp4") {
      fileExtension = "mp4";
    } else {
      console.warn("Type MIME non supporté. Utilisation de 'webm' par défaut.");
      fileExtension = "webm";
    }

    const fileName = `audio.${fileExtension}`;
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append(
      "practitioner_id",
      currentSessionInfo.practitioner.toString()
    );
    formData.append("subject_id", currentSessionInfo.subject.toString());
    formData.append("encounter_id", currentSessionInfo.encounter.toString());

    try {
      const response = await client.post("api/audio-file/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        console.log("Fichier audio téléchargé avec succès");
        const transcription = await client.get(
          `api/audio-file/${response.data.id}/transcribe/`
        );
        if (transcription.status === 200) {
          const transcriptMedical = transcription.data.transcription;
          setStreamUrl(
            `https://www.iavicenne.fr/api/correct/?transcription=${transcriptMedical}`
          );
          const deleteAudio = await client.delete(
            `api/audio-file/${response.data.id}/`
          );
          if (deleteAudio.status === 200) {
            console.log("fichier Audio supprimé (RGPD compliance)");
          }
        }
      } else {
        console.error(
          "Erreur lors du téléchargement du fichier audio:",
          response
        );
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier audio:", error);
    }
  };

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    // document.body.appendChild(audio);
    // console.log(audio);
    const notificationId = notifications.show({
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <Image
            fit="contain"
            src={brain}
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>Transcription en Cours</Text>
          <Loader className="ChatLoader" color="#94ADA7" type="dots" />
        </Group>
      ),

      opacity: 0.7,
      autoClose: false,
      withCloseButton: true,
    });
    setCurrentNotification(notificationId);
    uploadAudioBlob(blob);
  };


  return (
    <>
   
    <Group ref={audioRecorderRef}>
      <AudioRecorder
        onRecordingComplete={addAudioElement}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
        }}
        onNotAllowedOrFound={(err) => console.table(err)}
        downloadOnSavePress={false}
        downloadFileExtension="webm"
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
        }}
        showVisualizer={false}
        recorderControls={recorderControls}

      />
      </Group>
      <div className="audioVisualizer_Observation">
      {stream && isRecording && <AudioVisualizer audioStream={stream} />}
      </div>
      {loading && (
        <Center>
          <Loader className="ChatLoader" color="#94ADA7" type="dots" />
        </Center>
      )}
      {streamUrl && <StreamComponent url={streamUrl} />}
    </>
  );
}