// components/AntecedentsColumn.js

import {
  Grid,
  Divider,
  ScrollArea,
  Stack,
  Card,
  Title,
  Button,
  Text,
  CloseButton,
  Group
} from "@mantine/core";
import {
  IconEdit,
  IconX,
  IconShieldHalfFilled,
  IconShieldX,
  IconPillFilled,
} from "@tabler/icons-react";
import { useStore } from "../Store";
import { DeleteAntecedent, DeletetttFond } from "../hooks/useAntecedents";
import { RenewtttFond } from "../components/AddDocumentOrdonnance.js";

export function AntecedentsColumn() {
  const { selectedPatientInfo,setSelectedPatientInfo, setMiddleActive, setMiddleActiveTab, middleActive,showEncouterObjects } =
    useStore();
  const handleAntecedentsControlChange = () => {
    setMiddleActive("Modifier les Antecedents");
    setMiddleActiveTab("Modifier les Antecedents");
  };
 
  return (
    <Grid.Col span={2.2} className="antecedentsGridCol">
        {selectedPatientInfo &&(
      <Divider
        my="xs"
        label={
          <>
            <div>Antécédents&nbsp;&nbsp;</div>
            <IconEdit
              size={16}
              onClick={handleAntecedentsControlChange}
              cursor="pointer"
            />
          </>
        }
        labelPosition="center"
      />)}
      <ScrollArea
        h="92vh"
        w="100%"
        offsetScrollbars
        scrollHideDelay={100}
        scrollbarSize={6}
        style={{
          paddingLeft: 20,
          margin: "none",
          paddingBottom: 50,
          minWidth: "17.5vw",
           maxWidth: "17.5vw"
        }}
      >
        <Stack justify="flex-start">
          {selectedPatientInfo.antecedentsMedicaux &&
            selectedPatientInfo.antecedentsMedicaux.length > 0 && (
              <Card
                shadow="sm"
                padding="md"
                radius="12px"
                withBorder={true}
                style={{
                  zIndex: 1000,
                  maxWidth: "16.5vw",
                }}
              >
                <Title order={5}>Antécédents Médicaux</Title>

                <Stack
                  className="antMedList"
                  align="flex-start"
                  justify="flex-start"
                  gap="xs"
                >
                  {selectedPatientInfo.antecedentsMedicaux.map(
                    (antecedent, index) => {
                      const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                      return (
                        <Button
                          className="antMedButton"
                          justify="space-between"
                          size="xs"
                          variant="outline"
                          radius="12px"
                          color="#94ADA7"
                          onClick={handleAntecedentsControlChange}
                          rightSection={
                            middleActive === "Modifier les Antecedents" && (
                              <CloseButton
                                onClick={() => {
                                  DeleteAntecedent(
                                    "medical",
                                    antecedent[key]?.id,selectedPatientInfo, setSelectedPatientInfo
                                  );
                                }}
                                icon={
                                  <IconX size={18} stroke={1.5} color="red" />
                                }
                              />
                            )
                          }
                        >
                          <Text
                            fw={700}
                            tt="capitalize"
                            size="xs"
                            c="black"
                            truncate="end"
                            lineClamp={1}
                          >
                            {antecedent[key]?.mondo_name && (
                              <IconShieldHalfFilled
                                color="#94ADA7"
                                size={16}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            {!antecedent[key]?.mondo_name && (
                              <IconShieldX
                                color="#94ADA7"
                                size={16}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            &nbsp;
                            {antecedent[key]?.Title}{" "}
                          </Text>
                        </Button>
                      );
                    }
                  )}
                </Stack>
              </Card>
            )}
          {selectedPatientInfo.antecedentsChirurgicaux &&
            selectedPatientInfo.antecedentsChirurgicaux.length > 0 && (
              <Card
                shadow="sm"
                padding="md"
                radius="12px"
                withBorder={true}
                style={{
                  zIndex: 1000,
                  maxWidth: "16.5vw",
                }}
              >
                <Title order={5} style={{ stylemarginBottom: "20" }}>
                  Antécédents Chirurgicaux
                </Title>
                <Stack
                  className="antMedList"
                  align="flex-start"
                  justify="flex-start"
                  gap="xs"
                >
                  {selectedPatientInfo.antecedentsChirurgicaux.map(
                    (antecedent, index) => {
                      const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                      return (
                        <Button
                          className="antChirButton"
                          onClick={handleAntecedentsControlChange}
                          justify="space-between"
                          size="xs"
                          variant="outline"
                          radius="12px"
                          color="#94ADA7"
                          // fullWidth
                          // leftSection={
                          //   <IconShieldCheckered size={14} />
                          // }
                          rightSection={
                            middleActive === "Modifier les Antecedents" && (
                              <CloseButton
                                onClick={() => {
                                  DeleteAntecedent(
                                    "chirurgical",
                                    antecedent[key]?.id,selectedPatientInfo, setSelectedPatientInfo
                                  );
                                }}
                                icon={
                                  <IconX size={18} stroke={1.5} color="red" />
                                }
                              />
                            )
                          }
                        >
                          <Text
                            fw={700}
                            tt="capitalize"
                            size="xs"
                            color="black"
                            truncate="end"
                            lineClamp={1}
                          >
                            {antecedent[key]?.mondo_name && (
                              <IconShieldHalfFilled
                                color="#94ADA7"
                                size={16}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            {!antecedent[key]?.mondo_name && (
                              <IconShieldX
                                color="#94ADA7"
                                size={16}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            &nbsp;
                            {antecedent[key]?.Title}{" "}
                          </Text>
                        </Button>
                      );
                    }
                  )}
                </Stack>
              </Card>
            )}
          {selectedPatientInfo.antecedentsFamiliaux &&
            selectedPatientInfo.antecedentsFamiliaux.length > 0 && (
              <Card
                shadow="sm"
                padding="md"
                radius="12px"
                withBorder={true}
                style={{
                  zIndex: 1000,
                  maxWidth: "16.5vw",
                }}
              >
                <Title order={5} style={{ stylemarginBottom: "20" }}>
                  Antécédents Familiaux
                </Title>
                <Stack
                  className="antMedList"
                  align="flex-start"
                  justify="flex-start"
                  gap="xs"
                >
                  {selectedPatientInfo.antecedentsFamiliaux.map(
                    (antecedent, index) => {
                      const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                      return (
                        <Button
                          className="antFamButton"
                          onClick={handleAntecedentsControlChange}
                          justify="space-between"
                          size="xs"
                          variant="outline"
                          radius="12px"
                          color="#94ADA7"
                          // fullWidth
                          // leftSection={
                          //   <IconShieldCheckered size={14} />
                          // }
                          rightSection={
                            middleActive === "Modifier les Antecedents" && (
                              <CloseButton
                                onClick={() => {
                                  DeleteAntecedent(
                                    "familial",
                                    antecedent[key]?.id,selectedPatientInfo, setSelectedPatientInfo
                                  );
                                }}
                                icon={
                                  <IconX size={18} stroke={1.5} color="red" />
                                }
                              />
                            )
                          }
                        >
                          <Text
                            fw={700}
                            tt="capitalize"
                            size="xs"
                            color="black"
                            truncate="end"
                            lineClamp={1}
                          >
                            {antecedent[key]?.mondo_name && (
                              <IconShieldHalfFilled
                                color="#94ADA7"
                                size={16}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            {!antecedent[key]?.mondo_name && (
                              <IconShieldX
                                color="#94ADA7"
                                size={16}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              />
                            )}
                            &nbsp;
                            {antecedent[key]?.Title}{" "}
                          </Text>
                        </Button>
                      );
                    }
                  )}
                </Stack>
              </Card>
            )}
          {selectedPatientInfo.allergies &&
            selectedPatientInfo.allergies.length > 0 && (
              <Card
                shadow="sm"
                padding="md"
                radius="12px"
                withBorder={true}
                style={{
                  zIndex: 1000,
                  maxWidth: "16.5vw",
                }}
              >
                <Title order={5} style={{ stylemarginBottom: "20" }}>
                  Allergies
                </Title>
                <Stack
                  className="antMedList"
                  align="flex-start"
                  justify="flex-start"
                  gap="xs"
                >
                  {selectedPatientInfo.allergies.map((antecedent, index) => {
                    const key = Object.keys(antecedent)[0]; // Obtenir la première clé de l'objet antecedent
                    return (
                      <Button
                        className="antFamButton"
                        onClick={handleAntecedentsControlChange}
                        justify="space-between"
                        size="xs"
                        variant="outline"
                        radius="12px"
                        color="#94ADA7"
                        // fullWidth
                        // leftSection={
                        //   <IconShieldCheckered size={14} />
                        // }
                        rightSection={
                          middleActive === "Modifier les Antecedents" && (
                            <CloseButton
                              onClick={() => {
                                DeleteAntecedent(
                                  "allergies",
                                  antecedent[key]?.id,selectedPatientInfo, setSelectedPatientInfo
                                );
                              }}
                              icon={
                                <IconX size={18} stroke={1.5} color="red" />
                              }
                            />
                          )
                        }
                      >
                        <Text
                          fw={700}
                          tt="capitalize"
                          size="xs"
                          color="black"
                          truncate="end"
                          lineClamp={1}
                        >
                          {antecedent[key]?.mondo_name && (
                            <IconShieldHalfFilled
                              color="#94ADA7"
                              size={16}
                              style={{
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          {!antecedent[key]?.mondo_name && (
                            <IconShieldX
                              color="#94ADA7"
                              size={16}
                              style={{
                                verticalAlign: "middle",
                              }}
                            />
                          )}
                          &nbsp;
                          {antecedent[key]?.Title}{" "}
                        </Text>
                      </Button>
                    );
                  })}
                </Stack>
              </Card>
            )}
          {selectedPatientInfo.MedicationStatement &&
            selectedPatientInfo.MedicationStatement.length > 0 && (
              <Card
                shadow="sm"
                padding="md"
                radius="12px"
                withBorder={true}
                style={{
                  zIndex: 1000,
                  maxWidth: "16.5vw",
                }}
              >
                <Group justify="space-between">
                  <Title order={5} style={{ stylemarginBottom: "20" }}>
                    Traitement de fond
                  </Title>
                  {showEncouterObjects && <RenewtttFond />}
                </Group>
                <Stack
                  className="antMedList"
                  align="flex-start"
                  justify="flex-start"
                  gap="xs"
                >
                  {selectedPatientInfo.MedicationStatement.map(
                    (item, index) => {
                      return (
                        <Button
                          className="tttFondButton"
                          onClick={handleAntecedentsControlChange}
                          justify="space-between"
                          size="xs"
                          variant="outline"
                          radius="12px"
                          color="#94ADA7"
                          // fullWidth

                          rightSection={
                            middleActive === "Modifier les Antecedents" && (
                              <CloseButton
                                onClick={() => {
                                  DeletetttFond(item.medoc_id,selectedPatientInfo, setSelectedPatientInfo);
                                }}
                                icon={
                                  <IconX size={18} stroke={1.5} color="red" />
                                }
                              />
                            )
                          }
                        >
                          <Text
                            fw={700}
                            tt="capitalize"
                            size="xs"
                            c="black"
                            truncate="end"
                            lineClamp={1}
                          >
                            <IconPillFilled
                              color="#94ADA7"
                              size={16}
                              style={{
                                verticalAlign: "middle",
                              }}
                            />
                            &nbsp;{item.nom_commercial}
                          </Text>
                        </Button>
                      );
                    }
                  )}
                </Stack>
              </Card>
            )}
        </Stack>
      </ScrollArea>
    </Grid.Col>
  );
}


