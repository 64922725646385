
import {client} from "../api/axiosClient"

export const handleAntecedentClickInternal = async (value, antecedentSearchType, selectedPatientInfo, setSelectedPatientInfo, setShowAntecedentsSearch) => {

    if (antecedentSearchType === "Medical") {
      try {
        const updatedFields = {
          antecedentsMedicaux: [
            ...selectedPatientInfo.antecedentsMedicaux,
            { [value?.id]: value },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }

      setShowAntecedentsSearch(false);
    }
    if (antecedentSearchType === "Chirurgical") {
      try {
        const updatedFields = {
          antecedentsChirurgicaux: [
            ...selectedPatientInfo.antecedentsChirurgicaux,
            { [value?.id]: value },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }
      setShowAntecedentsSearch(false);
    }
    if (antecedentSearchType === "Familial") {
      try {
        const updatedFields = {
          antecedentsFamiliaux: [
            ...selectedPatientInfo.antecedentsFamiliaux,
            { [value?.id]: value },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }
      setShowAntecedentsSearch(false);
    }
    if (antecedentSearchType === "Allergie") {
      try {
        const updatedFields = {
          allergies: [
            ...selectedPatientInfo.allergies,
            { [value?.id]: value },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }
      setShowAntecedentsSearch(false);
    }
  };



