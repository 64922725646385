import { useState, useEffect } from "react";
import {
  Popover,
  Button,
  TextInput,
  Title,
  Group,
  Image,
  Text,
  Tooltip,
  Stack
} from "@mantine/core";
import {
  IconPlus,
  IconAlertTriangleFilled,
  IconCopyPlusFilled,
  IconPillFilled,
  IconFileArrowRight,
  IconBandageFilled,
  IconRadioactiveFilled,
  IconDropletFilled,
  IconFileCertificate,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useStore } from "../Store";
import { client } from "../api/axiosClient";
import brain from "../brain_ai_3.png";

export const SaveServiceRequestType = (ServiceType) => {
  const {
    currentUser,
    biologieData,
    radiologieData,
    dispositifMedicalData,
    ordonnanceLibreData,
    editorState
  } = useStore();
  const [loading, setLoading] = useState(false);
  const [indication, setIndication] = useState("");
  const [serviceRequestData, setServiceRequestData] = useState([]);
  useEffect(() => {
    if (ServiceType.ServiceType === "Biologie") {
      setServiceRequestData(biologieData);
    } else if (ServiceType.ServiceType === "Radiologie") {
      setServiceRequestData(radiologieData);
    } else if (ServiceType.ServiceType === "DispositifMedical") {
      setServiceRequestData(dispositifMedicalData);
    } else if (ServiceType.ServiceType === "OrdonnanceLibre") {
      // console.log(ordonnanceLibreData)
      const updatedData = [{
        practitioner: ordonnanceLibreData.practitioner,
        categoryContent: "OrdonnanceLibre",
        ServiceId: parseInt(ordonnanceLibreData.id, 10),
        ServiceContent: [{ EditorState: JSON.parse(editorState), tttAld: ordonnanceLibreData.tttAld }],
      }];
      setServiceRequestData(updatedData);
    }

  }, [
    ServiceType.ServiceType,
    biologieData,
    radiologieData,
    dispositifMedicalData,
    ordonnanceLibreData,
  ]);

  const handleSave = async () => {
    if (!indication) return;

    setLoading(true);
    // console.log(dispositifMedicalData);
    const serviceRequestTypeData = {
      practitioner: currentUser.id,
      indication: indication,
      type:ServiceType.ServiceType,
      ServiceRequest: serviceRequestData,
    };

    try {
      const response = await client.post(
        "api/service-request-type/",
        serviceRequestTypeData
      );
      if (response.status === 201) {
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src={brain}
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Ordonnance type enregistrée</Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
      } else {
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <IconAlertTriangleFilled color="red" size="25" />
              <Text fw={700}>Erreur</Text>
              <Text size="sm">
                Lors de l'enregistrement de l'ordonnance type
              </Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover width={250} trapFocus position="left" shadow="md" radius="12px">
      <Popover.Target>
        <Button
          className="printButton"
          variant="outline"
          size="xs"
          color="#94ADA7"
          radius="12px"
          style={{ boxShadow: "0 2px 5px #bebebe" }}
        >
          <IconCopyPlusFilled size={20} />
          {/* {ServiceType.ServiceType === "Biologie" && (
            <IconDropletFilled size={12} />
          )}
          {ServiceType.ServiceType === "Radiologie" && (
            <IconRadioactiveFilled size={12} />
          )}
          {ServiceType.ServiceType === "DispositifMedical" && (
            <IconBandageFilled size={12} />
          )}
          {ServiceType.ServiceType === "OrdonnanceLibre" && (
            <IconFileCertificate size={12} />
          )} */}
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Title size="h4" style={{ marginBottom: 20 }}>
          Ordonnance Type
        </Title>
        <Stack gap="xs">
        <TextInput
          placeholder="Indication / Titre"
          variant="filled"
          radius="12px"
          size="xs"
          value={indication}
          onChange={(event) => setIndication(event.currentTarget.value)}
        />

        <Button
          className="renouvelerButton"
          color="#94ADA7"
          radius="12px"
          size="xs"
          onClick={handleSave}
          loading={loading}
          disabled={loading || !indication}
          style={{ marginTop: "10px" }}
        >
          Enregistrer
        </Button>
        </Stack>
          
      
      </Popover.Dropdown>
    </Popover>
  );
};

export const RenewServiceRequest = ({ ordoTypeId, ServiceType }) => {
  const {
    currentUser,
    biologieData,
    setBiologieData,
    radiologieData,
    setRadiologieData,
    dispositifMedicalData,
    setDispositifMedicalData,
    ordonnanceLibreData,
    setOrdonnanceLibreData,
    selectedPatientInfo,
    currentSessionInfo,
    setNewEditorState
  } = useStore();
  const [serviceRequestData, setServiceRequestData] = useState([]);
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    if (ServiceType=== "Biologie") {
      setServiceRequestData(biologieData);
    } else if (ServiceType === "Radiologie") {
      setServiceRequestData(radiologieData);
    } else if (ServiceType === "DispositifMedical") {
      setServiceRequestData(dispositifMedicalData);
    } else if (ServiceType === "OrdonnanceLibre") {
      setServiceRequestData(ordonnanceLibreData);
    }
  }, [
    ServiceType,
    biologieData,
    radiologieData,
    dispositifMedicalData,
    ordonnanceLibreData,
  ]);

  const addService = async (value) => {
    setLoading(true);
    const ServiceRequestTypeData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String(ServiceType).trim(),
      ServiceId: parseInt(value.ServiceId, 10),
      ServiceContent: [
        {
          ...(ServiceType === "DispositifMedical"
            ? { label: value.ServiceContent[0].label }
            : {}),
          ...(ServiceType === "Biologie"
            ? { label: value.ServiceContent[0].label }
            : {}),
          ...(ServiceType === "Radiologie"
            ? { radiologie: value.ServiceContent[0].radiologie }
            : {}),
             ...(ServiceType === "Radiologie"
            ? { radiologie: value.ServiceContent[0].radiologie }
            : {}),
          tttAld: value.ServiceContent[0].tttAld,
        },
      ],
    };
    try {
      if (Array.isArray(serviceRequestData)) {
        const existingIndex = serviceRequestData.findIndex(
          (item) => item.ServiceId === value.ServiceId
        );
       

        if (existingIndex !== -1) {
          const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                {/* <Image
                  fit="contain"
                  src={brain}
                  alt="iAvicenne Logo"
                  width={15}
                  height={15}
                  style={{ margin: "0", display: "block" }}
                /> */}
                <Text fw={700} size="xs">
                {ServiceType === "DispositifMedical" ? (
                    `${value.ServiceContent[0].label.denominationCommerciale} déjà Prescrit`
                  ) : (
                    `${value.ServiceContent[0].label} déjà Prescrit`
                  )}
                </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
            "api/service-request/",
            ServiceRequestTypeData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            serviceRequestData.push({
              ...ServiceRequestTypeData,
              id: response.data.id,
            });
            if (ServiceType === "Biologie")
              setBiologieData(serviceRequestData);
            else if (ServiceType === "Radiologie")
              setRadiologieData(serviceRequestData);
           else if (ServiceType === "DispositifMedical") 
              setDispositifMedicalData(serviceRequestData);
      
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(
        `api/service-request-type/${ordoTypeId}/`
      );

      if (response.status === 200) {
        const ordonnance = response.data.ServiceRequest;
        if (ServiceType === "OrdonnanceLibre"){
          const NewEditoState ={
            trigger:true, 
            root: response.data.ServiceRequest[0].ServiceContent[0].EditorState,
          }
          setNewEditorState(NewEditoState)
         
          console.log("Ordonnance mise à jour");
        }
         
        else{
          for (const service of ordonnance || []) {
            // console.log(service);
            await addService(service);
          }
        }
        
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
      label={<Text size="xs">Renouveler l'ordonnance type</Text>}
      color="#94ADA7"
      withArrow
      multiline
      position="top"
      radius="12px"
      arrowOffset={25}
      arrowSize={8}
      transitionProps={{ transition: "pop", duration: 500 }}
      openDelay={1000}
    >
      <Button
        className="renouvelerButton"
        color="#94ADA7"
        radius="12px"
        size="xs"
        onClick={() => {
          renouvelerOrdo(ordoTypeId);
          if (ServiceType === "Biologie") {
            setActive("biologie");
            setActiveTab("biologie");
          } else if (ServiceType=== "Radiologie") {
            setActive("radiologie");
            setActiveTab("radiologie");
          } else if (ServiceType === "DispositifMedical") {
            setActive("dispositifsMedicaux");
            setActiveTab("dispositifsMedicaux");
          }
          else if (ServiceType === "OrdonnanceLibre") {
            setActive("autres");
            setActiveTab("autres");
          }
        }}
        loading={loading}
        disabled={loading}
      >
        <IconPlus size={16} />
        {ServiceType === "Biologie" && (
          <IconDropletFilled size={12} />
        )}
        {ServiceType === "Radiologie" && (
          <IconRadioactiveFilled size={12} />
        )}
        {ServiceType === "DispositifMedical" && (
          <IconBandageFilled size={12} />
        )}
        {ServiceType === "OrdonnanceLibre" && (
        <IconFileCertificate size={12} />
      )}
      </Button>
    </Tooltip>
  );
};

export const ShowServiceRequestType = (ServiceType) => {
  const {currentUser,setOrdonnancetypeData, setMiddleActiveTab, setMiddleActive } =
    useStore();
  const [loading, setLoading] = useState(false);

  // console.log(ServiceType);
  const handleFetchOrdonnances = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/service-request-type/?practitionner=${currentUser.id}&type=${ServiceType.ServiceType}`);
      if (response.status === 200) {
        setLoading(false);
        setOrdonnancetypeData(response.data.results);

        console.log(response.data);
        setMiddleActiveTab("Ordonnance Type");
        setMiddleActive("Ordonnance Type");
      } else {
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <IconAlertTriangleFilled color="red" size="25" />
              <Text fw={700}>Erreur</Text>
              <Text size="sm">
                lors de la récupération de la liste des Ordonnances types
              </Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
      }
    } catch (error) {
      notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <IconAlertTriangleFilled color="red" size="25" />
            <Text fw={700}>Erreur</Text>
            <Text size="sm">{error.message || "Une erreur est survenue"}</Text>
          </Group>
        ),
        opacity: 0.7,
        autoClose: true,
        withCloseButton: true,
      });
    }
  };

  return (
    <Button
      className="printButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="12px"
      style={{ boxShadow: "0 2px 5px #bebebe" }}
      onClick={handleFetchOrdonnances}
      loading={loading}
      disabled={loading}
    >
      {" "}
      <IconFileArrowRight size={16} />
      {ServiceType.ServiceType === "Biologie" && (
        <IconDropletFilled size={12} />
      )}
      {ServiceType.ServiceType === "Radiologie" && (
        <IconRadioactiveFilled size={12} />
      )}
      {ServiceType.ServiceType === "DispositifMedical" && (
        <IconBandageFilled size={12} />
      )}
       {ServiceType.ServiceType === "OrdonnanceLibre" && (
        <IconFileCertificate size={12} />
      )}
    </Button>
  );
};
