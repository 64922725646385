import { useState, useEffect } from "react";
import { useStore } from "../Store";
import { useForm } from "@mantine/form";
import GenerateCourrier from "./printCourrier";
import {
  Group,
  Stack,
  TextInput,
  Textarea,
  Button,

  rem,
  Title,
  Image,
  Text,
} from "@mantine/core";
import {client} from "../api/axiosClient"
import brain from "../brain_ai_3.png";

export function CourrierMedical() {
  const {
    currentUser,
    currentSessionInfo,
    selectedPatientInfo,
    observationData,
    setCourrierData,
  } = useStore();

  const [antecedents, setAntecedents] = useState([]);
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      destinataire: "",
      antecedents: "",
      motif: "",
      content: "",
      conclusion: "",
      politesse: "",
    },
    // validate: {
    //   synthese: (value) =>
    //     value.length < 1 ? "Votre Courr est vide" : null,
    // },
    onValuesChange: (values) => {
      updateCourrierData(values);
    },
  });

  useEffect(() => {
    setAntecedents([]);

    const updateAntecedents = (antecedentArray, familial = false) => {
      if (antecedentArray) {
        const updatedAntecedents = antecedentArray.map((item) => {
          const innerObject = item[Object.keys(item)[0]];
          let title = innerObject.Title.replace(/-/g, "").trim();
          if (familial) {
            title += " dans sa famille";
          }
          return { title: title };
        });
        setAntecedents((prevAntecedents) => [
          ...prevAntecedents,
          ...updatedAntecedents,
        ]);
      }
    };

    if (selectedPatientInfo) {
      updateAntecedents(selectedPatientInfo.antecedentsMedicaux);
      updateAntecedents(selectedPatientInfo.antecedentsChirurgicaux);
      updateAntecedents(selectedPatientInfo.antecedentsFamiliaux, true);
    }
  }, [selectedPatientInfo]);

  useEffect(() => {
    const antecedentsString = antecedents.map((a) => a.title).join(", ");
    form.setFieldValue("antecedents", antecedentsString);

    function calculateAge(birthDate) {
      const today = new Date();
      const birthDateObj = new Date(birthDate);
      let age = today.getFullYear() - birthDateObj.getFullYear();
      const monthDifference = today.getMonth() - birthDateObj.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
      ) {
        age--;
      }

      return age;
    }

    const Nom = selectedPatientInfo.NomNaissance;
    const Prenoms = selectedPatientInfo.ListePrenom;
    const Naissance = selectedPatientInfo.birthDate;
    const Age = calculateAge(Naissance);
    let patient;
    if (Age > 12) {
      patient =
        selectedPatientInfo.gender === "male"
          ? `Monsieur ${Prenoms} ${Nom} âgé de ${Age} ans`
          : `Madame ${Prenoms} ${Nom} âgée de ${Age} ans`;
    } else {
      patient = `l'Enfant ${Prenoms} ${Nom} âgé de ${Age} ans`;
    }
    form.setFieldValue("motif", `J'ai vu en consultation ${patient},`);
    form.setFieldValue("politesse", "Bien confraternellement, ");
  }, [antecedents, form]);


//   function animateText(text, delay = 1) {
//     let index = 0;
//     let currentText = '';
  
//     function addCharacter() {
//       if (index < text.length) {
//         currentText += text[index];
//         form.setFieldValue("content", currentText);
//         index++;
//         setTimeout(addCharacter, delay);
//       } else {
//         setLoading(false);
     
//       }
//     }
  
//     addCharacter();
//   }
  
  const updateCourrierData = (values) => {
    const CourrierData = {
        destinataire: values.destinataire,
        antecedents: values.antecedents,
        motif: values.motif,
        content:values.content,
        politesse: values.politesse,
    };
    setCourrierData(CourrierData);
  };

  const handleSubmit = () => {
   
  };
 
  // Votre fonction fetchData avec animation
  const fetchData = async () => {
    setLoading(true)
    const observation = `sexe:${selectedPatientInfo.gender}, destinatiare: urgences hospitalières, motif:${observationData.observationContent.motif}, sympôtmes:${observationData.observationContent.symptomes}, signes:${observationData.observationContent.symptomes},
    poids:${observationData.observationContent.poids}, pression artérielle:${observationData.observationContent.pressionArterielle} mmHg, fréquence cardique:${observationData.observationContent.frequenceCardique} bpm,
    spO2 %:${observationData.observationContent.spO2}, température:${observationData.observationContent.temperature}°C, synthese:${observationData.observationContent.synthese}, biologie:${observationData.observationContent.biologie},
    radiologie:${observationData.observationContent.radiologie}.`;
    
    try {
      const response = await client.get(`api/courrier/?observation=${observation}`);
      if (response.status === 200) {
        form.setFieldValue("content", response.data.courrier);
        setLoading(false)
        // animateText(response.data.courrier)// Utiliser l'animation ici
        const fullData = {
            destinataire: form.values.destinataire,
            antecedents: form.values.antecedents,
            motif: form.values.motif,
            content: response.data.courrier,
            politesse: form.values.politesse,
          }
          setCourrierData(fullData);
       
       
      
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
   
  };
  const isObservationDataEmpty = !observationData.synthese || Object.keys(observationData.synthese).length === 0;

  
  return (
<form onSubmit={(e) => e.preventDefault()}>
<Stack>
     <Group>
    <Button className="courrierButton" variant="light" color="#94ADA7" radius="12px"  onClick={fetchData} loading={loading} style={{width:233}} disabled={isObservationDataEmpty}>
    <Group gap="xs">
          <Image
            fit="contain"
            src={brain}
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text size="sm" fw={600}>Rédiger le Courrier</Text>

          </Group>
    </Button>
   
    <GenerateCourrier/>
    </Group>
  
        <TextInput
          variant="Unstyled"
          radius="12px"
          //   label={<Text size="xs" ta="left">destinatire</Text>}
          size="sm"
          placeholder="destinataire"
          {...form.getInputProps("destinataire")}
          x
        />

        <Textarea
          variant="Unstyled"
          radius="12px"
          // label={<Text size="xs">Antécédents</Text>}
          size="sm"
          placeholder="Antecedents du patient"
          {...form.getInputProps("antecedents")}
          autosize
          width="100%"
          resize="vertical"
        />

        <TextInput
          variant="Unstyled"
          radius="12px"
          // label={<Text size="xs">Motif</Text>}
          size="sm"
          placeholder="Motif de la consultation"
          {...form.getInputProps("motif")}
        />
        <Textarea
          variant="Unstyled"
          radius="12px"
          // label={<Text size="xs">Antécédents</Text>}
          size="sm"
          placeholder="Contenu du courrier"
          {...form.getInputProps("content")}
          autosize
          resize="vertical"
        />

        <TextInput
          variant="Unstyled"
          radius="12px"
          // label={<Text size="xs">Motif</Text>}
          size="sm"
          placeholder="Formule de politesse"
          {...form.getInputProps("politesse")}
        />
      </Stack>

    </form>
  );
}
