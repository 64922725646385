// ChangePasswordComponent.jsx

import React, { useState, useEffect } from "react";
import { client } from "../api/axiosClient";
import { useStore } from "../Store";
import { useDisclosure } from "@mantine/hooks";
import {
  Stack,
  Button,
  Group,
  PasswordInput,
  Progress,
  Text,
  Popover,
  Box,
  rem,
  Loader,
} from "@mantine/core";
import { IconAlertTriangleFilled, IconX, IconCheck } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

function PasswordRequirement({ meets, label }) {
  return (
    <Text
      c={meets ? "teal" : "red"}
      style={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX style={{ width: rem(14), height: rem(14) }} />
      )}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Inclut un nombre " },
  { re: /[a-z]/, label: "Inclut une lettre minuscule" },
  { re: /[A-Z]/, label: "Inclut une lettre majuscule" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Inclut un caractère spécial" },
];

function getStrength(password) {
  let multiplier = password.length > 8 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const Notification = (status, text) => {
  notifications.show({
    withBorder: true,
    loading: false,
    radius: "lg",
    color: "#94ADA7",
    title: (
      <Group gap="xs">
        {status === "Erreur" ? (
          <IconAlertTriangleFilled color="red" size="25" />
        ) : (
          <IconCheck color="green" size="25" />
        )}
        <Text fw={700}>{status}</Text>
        <Text size="sm">{text}</Text>
      </Group>
    ),
    opacity: 0.7,
    autoClose: 2000,
    withCloseButton: true,
  });
};

export function ChangePasswordComponent() {
  const [hasPassword, setHasPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const { currentUser } = useStore();
  const [visible, { toggle }] = useDisclosure(false);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState("");
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(newPassword)}
    />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  useEffect(() => {
    const checkPassword = async () => {
      if (currentUser.password?.length > 7) {
        setHasPassword(true);
      } else {
        setHasPassword(false);
      }
    };
    checkPassword();
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setErrors({});
    setMessage("");
    console.log({ srengh: getStrength(newPassword) });
    if (getStrength(newPassword) !== 100) {
      Notification("Erreur", "Le Mot de passe est faible");
      return;
    }
    if (newPassword !== confirmPassword) {
      Notification("Erreur", "Les mots de passe ne correspondent pas.");
      return;
    }
    const data = {
      new_password: newPassword,
    };
    if (hasPassword) {
      data.old_password = oldPassword;
    }
    try {
      console.log(oldPassword);
      const response = await client.post("/api/change-password/", data);
      if (response.status === 200) {
        Notification("Succès", "Le mot de passe a été changé avec succès.");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        Notification(
          "Erreur",
          <Text truncate="end" lineClamp={1}>
            {response}
          </Text>
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        Notification(
          "Erreur",
          <Text truncate="end" lineClamp={1}>
            {error.response.data}
          </Text>
        );
      } else {
        Notification("Erreur", "Une erreur est survenue.");
      }
    }
  };

  if (hasPassword === null) {
    return <Loader color="#94ADA7" type="dots" />;
  }

  return (
    <Stack>
      <Text>
        {hasPassword ? (
          <Text fw={700}>Changer le mot de passe</Text>
        ) : (
          <Group>
            <IconAlertTriangleFilled color="red" />
            <Text fw={700}> Mot de passe non défini</Text>
          </Group>
        )}
      </Text>
      <form onSubmit={handleChangePassword}>
        <Stack>
          {hasPassword && (
            <PasswordInput
              withAsterisk
              variant="filled"
              radius="md"
              defaultValue="secret"
              placeholder="Ancien Mot de Passe"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          )}
          <Popover
            opened={popoverOpened}
            position="bottom"
            width="target"
            transitionProps={{ transition: "pop" }}
          >
            <Popover.Target>
              <div
                onFocusCapture={() => setPopoverOpened(true)}
                onBlurCapture={() => setPopoverOpened(false)}
              >
                <PasswordInput
                  withAsterisk
                  variant="filled"
                  radius="md"
                  defaultValue="secret"
                  placeholder="Mot de Passe"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
            </Popover.Target>
            <Popover.Dropdown>
              <Progress color={color} value={strength} size={5} mb="xs" />
              <PasswordRequirement
                label="Inclut au minimum 8 caractères"
                meets={newPassword.length > 8}
              />
              {checks}
            </Popover.Dropdown>
          </Popover>
          <PasswordInput
            placeholder="Confirmer le Mot de Passe"
            defaultValue="secret"
            variant="filled"
            radius="md"
            visible={visible}
            onVisibilityChange={toggle}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />

          <Button
            type="submit"
            className="printButton"
            variant="light"
            size="md"
            color="#94ADA7"
            radius="12px"
            mt="10"
          >
            {hasPassword
              ? "Changer le mot de passe"
              : "Définir le mot de passe"}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
