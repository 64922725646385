/*******************************************************************
 * iAvicenne App.js - Composant principal de l'application
 * 
 * Ce fichier constitue le point d'entrée de l'application iAvicenne,
 * une plateforme médicale optimisée pour les praticiens de santé.
 * 
 * Architecture et approche :
 * - Utilisation du pattern Container/Presentational
 * - Gestion d'état via Zustand (useStore)
 * - Optimisation des performances via mémoisation (React.memo, useMemo)
 * - Approche modulaire avec des composants spécialisés
 * - Design UI/UX avec Mantine UI Framework
 *******************************************************************/

// --- SECTION 1: IMPORTS ---
// React core
import { useEffect, useMemo, memo, useState } from "react";

// Mantine UI components and hooks
import {
  MantineProvider,
  Stack,
  Button,
  Image,
  Grid,
  Text,
  Loader,
  Center,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useFullscreen } from "@mantine/hooks";

// Custom hooks
import { useAuth } from "./hooks/useAuth";
import { useStore } from "./Store";

// Structural components (elements)
import { NavBar } from "./elements/Navbar";
import { AntecedentsColumn } from "./elements/AntecedentsColumn";
import { MiddleColumn } from "./elements/MiddleColumn";
import { OrdonnanceColumn } from "./elements/OrdonnanceColumn";
import { FacturationColumn } from "./elements/Facturation";

// Functional components
import { Calendar } from "./components/Calendar";
import { Mailbox } from "./components/mail";
import { Login } from "./components/Login";
import { PractitionerDashboard } from "./components/Dashboard";

// Icons
import {
  IconDeviceDesktopUp,
  IconDeviceDesktopDown,
} from "@tabler/icons-react";

// Styles
import brain from "./brain_ai_3.png";
import background from "./arriere_plan.png";
import "./App.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

// --- SECTION 2: COMPOSANTS MÉMOÏSÉS ---
/**
 * Optimisation des performances via mémoïsation des composants coûteux
 * Ces composants ne seront recréés que si leurs props changent
 * Cela évite des rendus inutiles, notamment pour les composants avec beaucoup de données
 */
const MemoizedAntecedentsColumn = memo(AntecedentsColumn);
const MemoizedMiddleColumn = memo(MiddleColumn);
const MemoizedOrdonnanceColumn = memo(OrdonnanceColumn);
const MemoizedFacturationColumn = memo(FacturationColumn);
const MemoizedCalendar = memo(Calendar);
const MemoizedMailbox = memo(Mailbox);
const MemoizedPractitionerDashboard = memo(PractitionerDashboard);

/**
 * Composant pour les images d'arrière-plan
 * Extrait pour éviter des rendus inutiles puisque ces images ne changent jamais
 */
const BackgroundImages = memo(() => (
  <>
     <img src={background}  className="ArrierePlan" />
     <img src={brain}  className="ArrierePlanBrain" />
  </>
));

/**
 * Composant pour l'écran de connexion
 * Mémoïsé car il n'a pas besoin d'être reconstruit à chaque rendu de l'app
 */
const LoginScreen = memo(() => (
  <>
    <Login />
    <Stack>
      <Text
        className="homeTitle"
        fw={500}
        c="#94ADA7"
        style={{
          fontSize: 60,
          fontFamily: "Palatino",
          position: "absolute",
        }}
      >
        iAvicenne
      </Text>
      <Text
        className="homeTitle-2"
        fw={500}
        c="#94ADA7"
        style={{
          fontSize: 18,
          fontFamily: "Palatino",
          position: "absolute",
        }}
      >
        Changement de Paradigme Médical
      </Text>
    </Stack>
  </>
));

/**
 * Composant d'écran de chargement
 * Affiché pendant les états transitoires (chargement, initialisation)
 */
const LoadingScreen = memo(() => (
  <Center style={{ width: '100%', height: '100vh' }}>
    <Loader size="xl" color="#94ADA7" type="dots" />
  </Center>
));

// --- SECTION 3: COMPOSANT PRINCIPAL ---
/**
 * Composant App - Composant racine de l'application
 * 
 * Gère :
 * 1. L'authentification et les états transitoires (chargement)
 * 2. La structure conditionnelle de l'interface selon l'état d'authentification
 * 3. L'affichage des différentes sections de l'application via des rendus conditionnels optimisés
 */
export function App() {
  // --- Hooks et état ---
  const { toggle, fullscreen } = useFullscreen();  // Gestion du mode plein écran
  const { currentUser, loading, authInitialized } = useAuth();  // État d'authentification
  const [appReady, setAppReady] = useState(false);  // État de préparation de l'application
  
  // Extraction des états depuis le store global
  const {
    showPatientCard,
    showDashboard,
    showEncouterObjects,
    agendaOpened,
    mailboxOpened,
    showFacturation,
  } = useStore();

  /**
   * Effet pour éviter le flash de contenu pendant l'initialisation
   * Ajoute un délai minimal après l'initialisation de l'authentification
   * pour garantir que les états sont stables avant le rendu
   */
  useEffect(() => {
    if (authInitialized) {
      // Force un délai pour s'assurer que l'état est bien reflété dans l'UI
      const timer = setTimeout(() => {
        setAppReady(true);
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [authInitialized]);

  /**
   * Composants de vue mémoïsés pour éviter des recalculs inutiles
   * Cette approche déclarative des rendus conditionnels rend le code plus lisible
   * et plus performant que les rendus conditionnels en cascade
   */
  const viewComponents = useMemo(() => {
    if (!currentUser) return {};
    
    return {
      patientCard: showPatientCard && (
        <Grid key="patientGrid">
          <MemoizedAntecedentsColumn />
          <MemoizedMiddleColumn />
          {showEncouterObjects && <MemoizedOrdonnanceColumn />}
          {showFacturation && <MemoizedFacturationColumn />}
        </Grid>
      ),
      agenda: agendaOpened && <MemoizedCalendar key="calendar" />,
      mailbox: mailboxOpened && <MemoizedMailbox key="mailbox" />,
      dashboard: showDashboard && <MemoizedPractitionerDashboard key="dashboard" />
    };
  }, [
    currentUser, 
    showPatientCard, 
    showEncouterObjects,
    showFacturation,
    agendaOpened,
    mailboxOpened, 
    showDashboard
  ]);

  /**
   * Bouton plein écran mémoïsé
   * Ne change que si l'état de plein écran change
   */
  const fullscreenButton = useMemo(() => (
    <Button
      className="fullscreenButton"
      variant="filled"
      onClick={toggle}
      color={fullscreen ? "#94ADA7" : "#94ADA7"}
    >
      {fullscreen ? <IconDeviceDesktopDown /> : <IconDeviceDesktopUp />}
    </Button>
  ), [toggle, fullscreen]);

  // --- SECTION 4: RENDU CONDITIONNEL ---
  
  /**
   * Écran de chargement initial
   * Évite les problèmes de flash de contenu pendant l'initialisation
   */
  if (!appReady) {
    return (
      <MantineProvider>
        <div className="App">
          <BackgroundImages />
          <LoadingScreen />
        </div>
      </MantineProvider>
    );
  }

  /**
   * Rendu principal de l'application
   * Structure conditionnelle en trois états :
   * 1. Loading: Affiche l'écran de chargement
   * 2. Authentifié: Affiche l'interface principale
   * 3. Non authentifié: Affiche l'écran de login
   */
  return (
    <MantineProvider>
      <Notifications
        position="top-center"
        zIndex={1000}
        transitionDuration={500}
        containerWidth={370}
        containerHeigth={50}
        notificationMaxHeight={170}
        limit={5}
      />
      <div className="App">
        <BackgroundImages />

        {loading ? (
          <LoadingScreen />
        ) : currentUser ? (
          <div>
            {fullscreenButton}
            <Stack>
              <NavBar />
              {/* Affichage déclaratif des composants actifs */}
              {Object.values(viewComponents).filter(Boolean)}
            </Stack>
          </div>
        ) : (
          <LoginScreen />
        )}
      </div>
    </MantineProvider>
  );
}
