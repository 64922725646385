import { useStore } from "../Store";
import {
  Button,
  Image,
  Text,
  Group,
  Loader,
  Card,
  Stack,
  Title,
  List,
  Grid,
  RingProgress,
  ScrollArea
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconPills } from "@tabler/icons-react";
import { client } from "../api/axiosClient"
import brain from "../brain_ai_3.png";
import pubmed from "../Pubmed.png";



export function PropositionsTherapeutiquesButton({ maladie, mondoCode }) {
  const setPropositionsTherapeutiques = useStore(
    (state) => state.setPropositionsTherapeutiques
  );
  const setCurrentNotification = useStore(
    (state) => state.setCurrentNotification
  );
  const selectedPatientInfo = useStore(
    (state) => state.selectedPatientInfo
  );

  const handleClick = async () => {
    try {

      const notificationId = notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <Image
              fit="contain"
              src={brain}
              alt="iAvicenne Logo"
              width={25}
              height={25}
              style={{ margin: "0", display: "block" }}
            />
            <Text fw={700}>Traitements</Text>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Group>
        ),
        opacity: 0.7,
        autoClose: false,
        withCloseButton: true,


      });
      setCurrentNotification(notificationId);

      const response = await client.get(`api/traitements/fetch/?maladie=${maladie}&mondoCode=${mondoCode}&patient_id=${selectedPatientInfo.id}`);
      if (response.status === 200) {
        notifications.update({
          id: notificationId,
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src={brain}
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Recherche Terminée</Text>
              <IconCheck
                style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
              />
            </Group>
          ),
          opacity: 0.7,
          autoClose: 1000,
          withCloseButton: true,


        });
        setPropositionsTherapeutiques({
          ...response.data,
          maladie: maladie // Remplacez par la valeur souhaitée
        });
        // console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching examens complementaires:", error);
      notifications.show({
        title: "Erreur",
        message: "Impossible de récupérer les examens complémentaires",
        color: "red",
      });
    }
  };

  return (
    <Button
      onClick={handleClick}
      className="propExamenButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="12px"
      style={{ boxShadow: "0 2px 5px #bebebe" }}
    >
      <IconPills size={16} color="#94ADA7" />
    </Button>
  );
}

export function ShowPropositionsTherapeutiques() {
  const propositionsTherapeutiques = useStore(
    (state) => state.propositionsTherapeutiques
  );
  const medicamentQuery = useStore(
    (state) => state.medicamentQuery
  );
  const setMedicamentQuery = useStore(
    (state) => state.setMedicamentQuery
  );

  // console.log(propositionsTherapeutiques);

  if (!propositionsTherapeutiques.traitements) return null;

  // Function to extract only numbers from a string
  const extractNumbers = (str) => {
    if (!str) return '';
    return str.toString().replace(/\D/g, '');
  };

  return (
    <Stack>
      <Card shadow="lg" radius="12px" padding={20}>
        <Title size={22} ta="left" >
          {propositionsTherapeutiques?.maladie}
        </Title>
      </Card>
      <ScrollArea h={'77vh'} offsetScrollbars scrollHideDelay={100}>
      <Stack>
      {propositionsTherapeutiques.traitements.médicaments && (
        <>
       

            {/* <Title size={22}>Médicaments</Title> */}
            <Stack>
            {Object.entries(
              propositionsTherapeutiques.traitements.médicaments
            ).map(([key, value], index) => (
              
              <Card shadow="lg" radius="12px" padding={20} withBorder={true} style={{ marginBottom: "15" }}>
                <Title size={20} style={{ marginBottom: "5" }} ta="left" tt="capitalize" onClick={() => {setMedicamentQuery(key)}}>
                  {key}
                  <Text size="sm" style={{ paddingLeft: "15" }}>
                    {value.classe}
                  </Text>
                </Title>
                <Grid>
                  <Grid.Col span={2}>
                    <>
                      <RingProgress
                        size={90}
                        thickness={7}
                        roundCaps
                        sections={[
                          {
                            value: value.score,
                            color: value.score > 50 ? "lime" : "orange",
                          },
                        ]}
                        label={
                          <Text fw={700} ta="center" size="sm">
                            {value.score}%
                          </Text>
                        }
                      />
                    </>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <List ta="left" listStyleType="square" size="sm">
                      <List.Item>
                        <Text size="sm">
                          <strong>posologie</strong>: {value.posologie}
                        </Text>
                      </List.Item>

                      <List.Item>
                        <Text size="sm">
                          <strong>durée</strong>: {value.durée}
                        </Text>
                      </List.Item>

                      <List.Item>
                        <Text size="sm">
                          <strong>raisonnement</strong>: {value.reasoning}
                        </Text>
                      </List.Item>

                      <List.Item>
                        <Text size="sm">

                          {value.reference && (
                            <>
                              <Group>
                                <strong>références</strong>:

                                {Object.entries(value.reference).map(
                                  ([refKey, refValue], refIndex) => (
                                    <Group
                                      withPadding={true}
                                      key={refIndex}
                                      size="xs"
                                    >

                                      <a href={`https://pubmed.ncbi.nlm.nih.gov/${extractNumbers(refValue)}/`} target="_blank">
                                        <Image
                                          fit="contain"
                                          src={pubmed}
                                          alt="Pubmed Logo"
                                          width={17}
                                          height={17}
                                          style={{ margin: "0", display: "block" }}
                                        />
                                      </a>

                                    </Group>

                                  )
                                )}
                              </Group>
                            </>
                          )}

                        </Text>
                      </List.Item>




                    </List>
                  </Grid.Col>
                </Grid>
              </Card>
             
            ))}
          </Stack>

        </>
      )}
      {propositionsTherapeutiques.traitements.autres && (
        <Card shadow="lg" radius="12px"  style={{ marginBottom: "15" }}>
          <Title size={20} ta="left" style={{ marginBottom: "5" }}>
            Traitements Adjuvants
          </Title>
          {Object.entries(propositionsTherapeutiques.traitements.autres).map(
            ([key, value], index) => (
              <div key={index}>
                <List ta="left" withPadding={true}>
                  <List.Item>
                    <Text size="sm">{value}</Text>
                  </List.Item>
                </List>
              </div>
            )
          )}
        </Card>
      )}
    </Stack>
       </ScrollArea>
    </Stack>
  );
}
